import React, { useEffect, useState, useCallback } from 'react';
// import { useHistory } from 'react-router-dom';
// import constClass from '../../Constants/Constants';
// import Common from '../Common/common';
// import Popover from "react-popover";
// import CopyImg from '../Images/copy.svg';
import util from 'util';
import axios from 'axios';
import Loading from '../Loading/Loading';
import constClass from '../../Constants/Constants';

const StampCheck = ({ siteId, liffAccessToken, historyBack }) => {

  const [message, setMessage] = useState('');
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedStamp, setSelectedStamp] = useState({});
  const [stampCardMaster, setStampCardMaster] = useState([]);

  const refreshData = useCallback(async () => {
    try {
      //各データ取得APIを先に実行
      const stamp_master_promise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/list/line/check/`, { site_id: siteId, line_id: liffAccessToken });
      const stamp_master_data = (await stamp_master_promise).data;
      setStampCardMaster(stamp_master_data);
    } catch (err) {
      console.log(err);
    }
  }, [siteId, liffAccessToken]);

  const submitStamp = useCallback(async (stampcard_id) => {
    try {
      setLoading(true);
      // GPSを取得して再送信
      navigator.geolocation.getCurrentPosition(async position => {
        const { latitude, longitude } = position.coords;
        console.log('lon', longitude, 'lat', latitude);

        const params = {
          site_id: siteId,
          line_id: liffAccessToken,
          stampcard_id: stampcard_id,
          stamp_id: Number(selectedStamp[stampcard_id]),
          stamp_lon: longitude,
          stamp_lat: latitude
        }
        var result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/line/check`, params);
        if (result.data.distance) {
          setDistance(result.data.distance);
        }
        if (result.data.message) {
          setMessage(result.data.message);
        } else {
          setMessage("エラーが発生しました。");
        }
        setLoading(false);
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        // console.log(util.inspect(err));
        setMessage(err.response.data.message);
      } else {
        console.log(util.inspect(err));
        setMessage("エラーが発生しました。");
      }
      setLoading(false);
    }
  }, [siteId, liffAccessToken, selectedStamp]);

  const changeSelectedStamp = useCallback((stampcardId) => (e) => {
    const value = e.target.value;
    setSelectedStamp({ ...selectedStamp, [stampcardId]: value });
  }, [selectedStamp]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <div id="stampCheck" className="px-0-env">
      {stampCardMaster.map(stampCard => (
        <div className='bg-white mt-3' key={stampCard.stampcard_id}>
          <div className="row mx-0">
            <div className="col mx-3 my-0 py-1 px-0 text-center">
              <div className="px-2 pt-2">
                {stampCard.stampcard_name}<br />
                {stampCard.start_date} ～ {stampCard.end_date}
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col mx-3 my-0 py-1 px-0 text-left">
              <div className="px-2 pb-2">
                <select className="custom-select w-100 text-center" value={selectedStamp[stampCard.stampcard_id] || ''} onChange={changeSelectedStamp(stampCard.stampcard_id)}>
                  <option value={''}>-- スタンプを選択してください --</option>
                  {stampCard.m_stamp.map((stamp) =>
                    <option value={stamp.stamp_id} key={stamp.stamp_id}>{stamp.stamp_name}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          {!!selectedStamp[stampCard.stampcard_id] &&
            <div className="row mx-0">
              <div className="col mx-3 my-0 py-1 px-0">
                <div className="px-2 py-3  stamp- bg-stamp text-center">
                  <img className="w-25" src={`/${siteId}/${constClass.STAMP_IMAGE_DIR}${stampCard.m_stamp.find(s => s.stamp_id === Number(selectedStamp[stampCard.stampcard_id])).stamp_image_url}`} alt={''} />
                </div>
                <div className="px-2 py-3 text-center">
                  <button
                    className="btn btn-enable w-100 py-2"
                    onClick={() => { submitStamp(stampCard.stampcard_id); }}>
                    GPSを確認
                  </button>
                </div>
                <div className="px-2 py-1 text-center">
                  {distance}
                </div>
                <div className="px-2 py-1 text-center">
                  {message}
                </div>
              </div>
            </div>
          }
        </div>
      ))}
      <div className="row mx-0 mt-2 mb-5">
        <div className="col mx-3 my-0 py-1 px-0 text-center">
          <button
            className="btn btn-enable w-100 py-2"
            onClick={() => { historyBack(); }}>
            戻る
          </button>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default StampCheck;