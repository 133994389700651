import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import Common from '../Common/common';

const CampaignEntry = (props) => {
  const { siteId, customer_id, campaign, my_entry, setting, liffAccessToken } = props;
  const [error, setError] = useState(false);
  const [end, setEnd] = useState(false);
  const [ready, setReady] = useState(false);
  const history = useHistory();

  const entry = useCallback(async () => {
    if (setting && my_entry && campaign) {
      if (new Date(campaign.campaign_end) < new Date()) {
        setEnd(true);
        setReady(true);
      } else {
        const data = {
          'site_id': siteId,
          'customer_id': customer_id,
          'campaign_id': my_entry.campaign_id,
          'privacy_policy': constClass.FLAG.OFF,
          'status': constClass.STATUS.REG,
          'entry_date': my_entry.entry_date ? my_entry.entry_date : new Date(),
          'line_id': liffAccessToken,
        };
  
        try {
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry/line`, data);
          setError(false);
        } catch (e) {
          console.log(e);
          setError(true);
        } finally {
          setReady(true);
        }
      }
    } else {
      setError(true);
      setReady(true);
    }
  }, [siteId, customer_id, my_entry, setting, campaign, liffAccessToken]);

  const back = () => {
    if (setting['HIDE_FOOTER'] === constClass.FLAG.ON) {
      if (window.liff.isInClient()) {
        window.liff.closeWindow();
      }
    } else {
      history.goBack();
    }
  }

  useEffect(() => {
    entry();
  }, [entry]);

  return (
    <div id="campaignentry" className="px-0-env">
      {ready &&
        <div className="container bg-white">
          {(!error && !end) &&
            <React.Fragment>
              <div className="campaign-entry-header">
                {setting['CAMPAIGN_ENTRY_IMAGE'] &&
                  <div className="campaign-entry-image row mx-0">
                    <div className="col p-0 text-center text-bold">
                      <img className="w-100" src={`/${siteId}/${setting['CAMPAIGN_ENTRY_IMAGE']}`}
                        onError={e => e.target.style.display = 'none'}
                        alt="title" />
                    </div>
                  </div>
                }
                <div className="campaign-entry-header-text row mx-0">
                  <div className="col mx-3 my-3 p-0 text-center text-bold">
                    {Common.getBrString(campaign ? campaign.entry_header : '')}
                  </div>
                </div>
              </div>
              <div className="campaign-entry-content">
                <div className="campaign-entry-content-text row mx-0">
                  <div className="col mx-3 my-3 p-0 text-left">
                    {Common.getBrString(campaign ? campaign.entry_content : '')}
                  </div>
                </div>
                <div className="campaign-entry-content-button row mx-0">
                  <div className="col mx-3 my-2 p-0 text-left">
                    <button className="btn-lg btn-enable w-100 py-2"
                      onClick={() => { back(); }}>
                      {setting['HIDE_FOOTER'] === constClass.FLAG.ON ? '閉じる' : '戻る'}
                    </button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
          {end &&
            <React.Fragment>
              <div className="row mx-0">
                <div className="col mx-3 my-1 p-0 text-left bg-white">
                  {Common.getBrString(campaign ? campaign.entry_end : '')}
                </div>
              </div>
              <div className="row mx-0">
                <div className="col mx-3 my-1 p-0 text-left bg-white">
                  <button className="btn-lg btn-enable w-100 py-2"
                    onClick={() => { back(); }}>
                    {setting['HIDE_FOOTER'] === constClass.FLAG.ON ? '閉じる' : '戻る'}
                  </button>
                </div>
              </div>
            </React.Fragment>
          }
          {error &&
            <React.Fragment>
              <div className="row mx-0">
                <div className="col mx-3 my-1 p-0 text-left bg-white text-danger">
                  エラーが発生しました。
                </div>
              </div>
              <div className="row mx-0">
                <div className="col mx-3 my-1 p-0 text-left bg-white text-danger">
                  <button className="btn-lg btn-enable w-100 py-2"
                    onClick={() => { back(); }}>
                    {setting['HIDE_FOOTER'] === constClass.FLAG.ON ? '閉じる' : '戻る'}
                  </button>
                </div>
              </div>
            </React.Fragment>
          }
        </div>
      }
    </div>
  );
}

export default CampaignEntry;