import constClass from '../../Constants/Constants';
import Common from '../Common/common';
import moment from 'moment';
import React from 'react';

const Ticket = ({ ticket, ticketClick, backClick, disabled, siteId }) => {

  return (
    <div id="ticketUse" className="px-0-env">
      <div className="mx-3 mt-1 mb-3 bg-white border">
        <div className="row mx-0 px-3-env pt-2 pb-1 card-header">
          <div className="col py-1 px-1 align-self-center text-center">
            <h4 className="p-0 m-0">{ticket.ticket_name}</h4>
            {ticket.ticket_summary && <span className="p-0 m-0">{ticket.ticket_summary}</span>}
          </div>
        </div>
            {(ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) >= ticket.ticket_times) &&
              <div className="row mx-0 px-3 pt-2 pb-1">
                <div className="col py-1 px-1 align-self-center text-center">
                  <p
                    className="mb-0 py-1 text-danger w-100">
                    このクーポンは使用済みです
                  </p>
                </div>
              </div>
            }
        <div className="row mx-0 px-3 pt-2 pb-1">
          {ticket.ticket_image_url && (ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) >= ticket.ticket_times) &&
            <div className="col py-1 px-1 align-self-center text-center">
              {!ticket.hp_url && <div className="w-50 mx-auto ticketuse"><img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} /></div>}
              {ticket.hp_url &&
                <a href={Common.getExternalUrl(ticket.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                    <div className='ticketuse'>
                      <img className="w-50" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} />
                    </div>
                  <br />
                  <u>公式サイトへ</u>
                </a>
              }
            </div>
          }
          {ticket.ticket_image_url && !(ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) >= ticket.ticket_times) &&
            <div className="col py-1 px-1 align-self-center text-center">
              {!ticket.hp_url && <div className="w-50 mx-auto"><img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} /></div>}
              {ticket.hp_url &&
                <a href={Common.getExternalUrl(ticket.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                  <img className="w-50" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} />
                  <br />
                  <u>公式サイトへ</u>
                </a>
              }
            </div>
          }
        </div>
        <div className="row mx-0 px-3 pt-1 pb-1">
          <div className="col py-1 px-1 align-self-center">
            <div className="row pb-3">
              <div className="col text-center">
                <span className="p-0 m-0">{Common.getBrString(ticket.ticket_detail)}</span>
              </div>
            </div>
            {ticket.ticket_notes &&
              <div className="row pb-1">
                <div className="col small">
                  【注意事項】
                  <span className="p-0 m-0">{Common.getBrString(ticket.ticket_notes)}</span>
                </div>
              </div>
            }
            <div className="row pb-3">
              <div className="col small">
                【有効期限】
                <br />
                <span className="p-0 m-0">{Common.getDateObject1(ticket.ticket_end_date).str}</span>
              </div>
            </div>
            {/* {ticket.ticket_times > 1 &&
              <div className="row pb-1">
                <div className="col text-center small">
                  <span className="p-0 m-0">最大{ticket.ticket_times}回使用可能({ticket.t_ticket_use.length > 0 ? ticket.t_ticket_use.length + '回使用済み' : '未使用'})</span>
                </div>
              </div>
            } */}
            <div className="row pb-3">
              <div className="col">
                {(ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) < ticket.ticket_times) &&
                  <div className="text-center">
                    <p
                      className="mb-0 py-1 text-alert w-100">
                      この画面をスタッフへご提示ください
                    </p>
                  </div>
                }
                {ticket.t_ticket_use.map((use, idx) =>
                  <div className="row p-0 m-0" key={use.ticket_times}>
                    <div className="col-4 p-0 m-0 text-right">
                      {idx === 0 ? '使用日時：' : null}
                    </div>
                    <div className="col-8 p-0 m-0 text-left">
                      {moment(use.use_date).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-enable w-100"
                  disabled={disabled}
                  onClick={backClick}>
                  戻る
                </button>
              </div>
            </div>
            {(ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) < ticket.ticket_times) &&
            <React.Fragment>
            <div className="row pt-3 mt-3 border-top">
              <div className="col">
                <div className="text-center">
                    <p
                      className="mb-0 py-1 text-secondary w-100">
                      以下のボタンは店舗用です。<br />
                      タップするとクーポンを使用します。
                    </p>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {(ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) < ticket.ticket_times) &&
                  <div>
                    <button
                      onClick={ticketClick(ticket.ticket_id)}
                      className="btn btn-secondary w-100">
                      クーポンを使用する
                    </button>
                  </div>
                }
              </div>
            </div>
            </React.Fragment>
          }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ticket;