import React, { useEffect, useState, useCallback, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
// import constClass from '../../Constants/Constants';
// import Common from '../Common/common';
// import Popover from "react-popover";
// import CopyImg from '../Images/copy.svg';
import util from 'util';
import axios from 'axios';
import QRCodeScanner from '../Common/QRCodeScanner';
import queryString from 'query-string';
import Loading from '../Loading/Loading';

const StampScan = ({ siteId, liffAccessToken, historyBack, stamp_uuid, stampRef }) => {

  const [scanning, setScanning] = useState(false);
  const [scanMessage, setScanMessage] = useState('二次元コードを読み取ってください。');
  const [qrCodeData, setQrCodeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const timerFlagRef = useRef(false);

  const reset = useCallback(() => {
    setScanning(true);
    setLoading(false);
    setQrCodeData(null);
  }, []);

  const submitStamp = useCallback(async (uuid, lon, lat) => {
    console.log(uuid);
    try {
      setLoading(true);
      const params = {
        site_id: siteId,
        line_id: liffAccessToken,
        stamp_uuid: uuid,
        stamp_lon: lon,
        stamp_lat: lat
      }
      var result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/line`, params);
      if (result.data.stamp) {
        stampRef.current = result.data.stamp;
        setScanMessage("スタンプを獲得しました！");
        setScanning(false);
        setLoading(false);
        historyBack();
      }
      else {
        if (result.data.message) {
          setScanMessage(result.data.message);
        } else {
          setScanMessage("エラーが発生しました。");
        }
        reset();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        if (err.response.data.gps_req) {
          // GPSを取得して再送信
          navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            console.log('lon', longitude, 'lat', latitude);
            submitStamp(uuid, longitude, latitude);
          });
        } else {
          // console.log(util.inspect(err));
          setScanMessage(err.response.data.message);
          reset();
        }
      } else {
        console.log(util.inspect(err));
        setScanMessage("エラーが発生しました。");
        reset();
      }
    }
  }, [historyBack, siteId, liffAccessToken, reset, stampRef]);

  const timerReset = () => {
    if (timerFlagRef) {
      timerFlagRef.current = false;
    }
  }

  useEffect(() => {
    if (qrCodeData && !timerFlagRef.current) {
      // 直接LIFFが起動できるようにQRコードはURLにする
      // URLからstamp_uuidを取り出す
      const qs = queryString.parse(qrCodeData);
      const uuid = qs.stamp_uuid;
      if (uuid) {
        submitStamp(uuid, null, null);
      } else {
        setScanMessage('二次元コードの形式が異なります。');
        reset();
      }
      timerFlagRef.current = true;
      setTimeout(timerReset, 1000);
    } else {
      setQrCodeData(null);
    }
  }, [qrCodeData, submitStamp, reset]);

  useEffect(() => {
    if (stamp_uuid) {
      submitStamp(stamp_uuid, null, null);
    } else {
      setScanning(true);
    }
  }, [stamp_uuid, submitStamp]);

  useEffect(() => {
    stampRef.current = null;
  }, [stampRef]);

  return (
    <div id="couponList" className="px-0-env">
      <div className="row mx-0 mt-2">
        <div className="col mx-3 my-0 py-1 px-0 text-left bg-white">
          <div className="mt-2 px-2 py-0 text-center">
            <div id="container" className="container">
              <div className="container-fluid section-white m-0 py-2 vh-50 overflow-auto">
                <div className="row mx-0 px-3-env text-center form-group">
                  <div className="col px-0 align-self-center">
                    <span>二次元コード読み取り</span>
                  </div>
                </div>
                <div className="row mx-0 px-3-env mt-3 mb-2 text-left">
                  <div className="col px-0 align-self-center">
                    {scanning && <QRCodeScanner
                      setQrCodeData={setQrCodeData}
                      scanning={scanning}
                    />}
                    {!scanning && <div className={`w-100 embed-responsive embed-responsive-1by1`}></div>}
                  </div>
                </div>
                <div className="row mx-0 px-3-env mt-2 mb-3 text-center">
                  <div className="col px-0 align-self-center text-danger">{scanMessage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 mt-2 mb-5">
        <div className="col mx-3 my-0 py-1 px-0 text-center">
          <button
            className="btn btn-enable w-100 py-2"
            onClick={() => { historyBack(); }}>
            戻る
          </button>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default StampScan;