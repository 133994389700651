import React, { useState, useEffect, useCallback, useRef } from 'react';
// import Barcode from './Barcord';
//import CheckDigit from './CheckDigit';
// import Logo from '../Images/nnrg_logo.png';
import axios from 'axios';
import moment from 'moment';
// import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import util from 'util';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'

// let membership_id = null;

const StampCard = ({ siteId, customerId, lineId, liffAccessToken, setting, stampRef }) => {
  const [stampCardMaster, setStampCardMaster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stamp, setStamp] = useState({});
  const [stampSummary, setStampSummary] = useState({});
  const [activeIndex, setActiveIndex] = useState({});
  const swiperRef = useRef({});
  const imageRef = useRef();

  //バックエンドサーバから会員に関する情報を取得
  const refreshData = useCallback(async () => {
    try {
      //各データ取得APIを先に実行
      const stamp_master_promise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/list/line/`, { site_id: siteId, line_id: liffAccessToken });
      const stamp_list_promise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/list/line/`, { site_id: siteId, line_id: liffAccessToken, customer_id: customerId });

      const stamp_master_data = (await stamp_master_promise).data;
      const stamp_list_data = (await stamp_list_promise).data;

      var stampCard = {};
      var t_stamp = stamp_list_data.t_stamp || [];
      var stampSum = {};
      var index = {};

      // スタンプカード種別ごとにスタンプ状況を設定
      for (const stamp_master of stamp_master_data) {
        stampCard[stamp_master.stampcard_id] = [];
        stampSum[stamp_master.stampcard_id] = {
          sum: t_stamp.filter(p => p.stampcard_id === stamp_master.stampcard_id).reduce((prev, p) => prev + p.point, 0),
          last: t_stamp.filter(p => p.stampcard_id === stamp_master.stampcard_id).reduce((prev, p) => prev < p.stamp_date ? p.stamp_date : prev, "")
        };
        index[stamp_master.stampcard_id] = 0;
        // ポイントデータを1ポイント1要素に変換
        var points = t_stamp.filter(p => p.stampcard_id === stamp_master.stampcard_id).reduce(
          (prev, p) => {
            var s = stamp_master.m_stamp.find(s => s.stamp_id === p.stamp_id);
            var newStamp = stampRef.current ? stampRef.current : {};
            for (var i = 0; i < p.point; i++) {
              prev.push({ image: s ? s.stamp_image_url : null, used: i < p.use_point, new: (p.stampcard_id === newStamp.stampcard_id && p.stamp_id === newStamp.stamp_id && p.stamp_no === newStamp.stamp_no) })
            }
            return prev;
          }
          , []);

        if (points.length > 0) {
          // 獲得しているポイントでループ
          for (var i = 0; i < points.length; i += stamp_master.point_limit) {
            index[stamp_master.stampcard_id] = (i/stamp_master.point_limit);
            var card = { stamp: [], checkFilled: true, checkUsed: true, checkNew: false, stampcard_id: stamp_master.stampcard_id };
            for (var j = 0; j < stamp_master.point_limit; j++) {
              if (i + j < points.length) {
                if (j + 1 === stamp_master.point_limit && stamp_master.stamp_achievementimage_url) {
                  card.stamp.push(points[i + j] ? { image: stamp_master.stamp_achievementimage_url, new: points[i + j].new } : null);
                } else {
                  card.stamp.push(points[i + j] ? { image: points[i + j].image, new: points[i + j].new } : null);
                }
                card.checkUsed = card.checkUsed & points[i + j].used;
                card.checkNew = card.checkNew | points[i + j].new;
              } else {
                card.stamp.push(null);
                card.checkUsed = false;
                card.checkFilled = false;
              }
            }
            stampCard[stamp_master.stampcard_id].push(card);
          }
        } else {
          stampCard[stamp_master.stampcard_id].push({ stamp: (new Array(stamp_master.point_limit)).fill(null), checkFilled: false, checkUsed: false, stampcard_id: stamp_master.stampcard_id });
        }
      }

      setStamp(stampCard);
      setStampCardMaster(stamp_master_data);
      setStampSummary(stampSum);
      setActiveIndex(index);
    } catch (err) {
      console.log(err);
    }
  }, [siteId, customerId, liffAccessToken, stampRef]);

  const submitStampCard = useCallback(async (stampcard_id) => {
    console.log(stampcard_id);
    if (!window.confirm('交換にはスタッフの確認が必要です。交換してもよろしいですか？')) {
      return;
    }
    try {
      setLoading(true);
      const params = {
        site_id: siteId,
        line_id: liffAccessToken,
        stampcard_id,
      }
      var result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/line/use`, params);
      if (result.data.stampcard) {
        window.alert('交換しました。');
        await refreshData();
        setLoading(false);
      } else {
        if (result.data.message) {
          window.alert(result.data.message);
        } else {
          window.alert("エラーが発生しました。");
        }
        setLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        // console.log(util.inspect(err));
        window.alert(err.response.data.message);
      } else {
        console.log(util.inspect(err));
        window.alert("エラーが発生しました。");
      }
      setLoading(false);
    }
  }, [siteId, liffAccessToken, refreshData]);

  const animationEnded = useCallback(() => {
    const tmp_stamp = { ...stamp };
    Object.keys(swiperRef.current).forEach((idx) => {
      const swiper = swiperRef.current[idx];
      var index = swiper.activeIndex;
      if (index < (stamp[idx].length - 1)) {
        swiper.slideTo(index + 1);
        // tmp_stamp[idx][index + 1].stamp = stamp[idx][index + 1].stamp.map((s) => (s && s.new) ? {...s, re: true} : s);
        // imageRef.current.classList.remove('stamp-new');
        // void imageRef.current.offsetWidth
        // imageRef.current.classList.add('stamp-new');

        if (document.querySelectorAll('.stamp-new')) {
          Array.from(document.querySelectorAll('.stamp-new')).forEach(e => {
            e.classList.remove('stamp-new')
            //-hack for reflow
            void e.offsetWidth
            e.classList.add('stamp-new')
          })
        }
      }
    });
    setStamp(tmp_stamp);
  }, [stamp]);

  const getActiveIndex = (stampcard_id) => (swiper) => {
    setActiveIndex({ ...activeIndex, [stampcard_id]: swiper.activeIndex });
  }

  const getActiveCard = useCallback((stampcard_id) => {
    if (stamp[stampcard_id] && (activeIndex[stampcard_id] !== null && activeIndex[stampcard_id] !== undefined)) {
      const stamp_data = stamp[stampcard_id][activeIndex[stampcard_id]];
      console.log(stamp_data)
      return stamp_data;
    } else {
      return {};
    }
  }, [stamp, activeIndex]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    if (imageRef.current && swiperRef.current) {
      imageRef.current.onanimationend = animationEnded;
    }
  }, [stampCardMaster, animationEnded]);

  const renderStamp = (stamp, idx, col) =>
    <div className={`col px-1 text-center align-self-center stamp-${idx + col < stamp.length ? (stamp[idx + col] ? '' : 'off') : 'null'}`}>
      <p className={`mb-0 mx-auto ${(stamp[idx + col] && stamp[idx + col].new) ? 'stamp-new' : ''}`} ref={(stamp[idx + col] && stamp[idx + col].new) ? imageRef : null}>{idx + col < stamp.length ? (stamp[idx + col] ? <img className="w-100" src={`/${siteId}/${constClass.STAMP_IMAGE_DIR}${stamp[idx + col].image}`} alt={''} /> : (idx + col + 1)) : ''}</p>
    </div>

  return (
    <div id="stampcard" className="px-0-env">
      {stampCardMaster.map(stampCard => (
        <React.Fragment key={stampCard.stampcard_id}>
          <div className="row mx-0">
            <div className="col mx-3 my-1 p-0 text-left bg-white">
              <div className="row mx-0">
                <div className="col mt-3 mx-2 text-center">
                  <h4>{/* stampCard.stampcard_name */}</h4>
                  {(!!stampSummary[stampCard.stampcard_id] && !!stampSummary[stampCard.stampcard_id].sum) && <span className="mr-1">来館回数:{stampSummary[stampCard.stampcard_id].sum}回</span>}
                  {(!!stampSummary[stampCard.stampcard_id] && !!stampSummary[stampCard.stampcard_id].last) && <span className="ml-1">最終来館日:{moment(stampSummary[stampCard.stampcard_id].last).format('YYYY/MM/DD')}</span>}
                </div>
              </div>
              <Swiper
                modules={[Navigation, Pagination]}
                navigation
                pagination={{ clickable: true, el: "#pagination" }}
                slidesPerView={'auto'}
                initialSlide={stamp[stampCard.stampcard_id].findIndex(s => s.checkNew) < 0 ? (stamp[stampCard.stampcard_id].length - 1) : stamp[stampCard.stampcard_id].findIndex(s => s.checkNew)}
                onSwiper={(swiper) => swiperRef.current[stampCard.stampcard_id] = swiper}
                onSlideChangeTransitionEnd={getActiveIndex(stampCard.stampcard_id)}
              >
                {stamp[stampCard.stampcard_id].map((card, idx) =>
                  <SwiperSlide key={idx}>
                    <div className="row mx-0" key={idx}>
                      <div className="col mt-3 mb-1 mx-2">
                        <div className={`bg-stamp rounded`}>
                          <div className="row px-3 py-2">
                            <div className="col text-center text-white">
                              <span className="mr-1">カード{idx + 1}</span>
                              <span>{card.checkUsed ? '交換済み' : card.checkFilled ? 'ポイント達成' : '現在のポイント'}</span>
                            </div>
                          </div>
                          <div className={`row px-3 py-2 ${card.checkUsed ? 'opacity' : ''}`}>
                            <div className="col">
                              {card.stamp.map((s, idx) => (
                                idx % 5 === 0 ? (
                                  <div className="row my-1" key={idx}>
                                    {renderStamp(card.stamp, idx, 0)}
                                    {renderStamp(card.stamp, idx, 1)}
                                    {renderStamp(card.stamp, idx, 2)}
                                    {renderStamp(card.stamp, idx, 3)}
                                    {renderStamp(card.stamp, idx, 4)}
                                  </div>
                                ) : null
                              )
                              )}
                            </div>
                          </div>
                          <div className="row px-3 pt-1 pb-2">
                            <div className="col text-center text-white small ">
                              {/* {(card.checkFilled && !card.checkUsed) &&
                                <button onClick={() => submitStampCard(card.stampcard_id)} className="btn btn-enable w-100">
                                  交換する
                                </button>
                              }
                              {(!card.checkFilled || !!card.checkUsed) && */}
                                <div className="pt-3 pb-4"></div>
                              {/* } */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
              <div className="row mx-0">
                <div className="col mx-3 mt-3 mb-1 p-0">
                  <Link to={`/${siteId}/?page=${constClass.STAMPSCAN}`} >
                    <button className="btn-lg btn-active w-100 py-2">
                      カメラを起動してポイント獲得
                    </button>
                  </Link>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col mx-3 mt-1 mb-3 p-0">
                  <button
                    disabled={!getActiveCard(stampCard.stampcard_id).checkFilled || getActiveCard(stampCard.stampcard_id).checkUsed}
                    className={`btn-lg btn-enable w-100 py-2`}
                    onClick={() => submitStampCard(stampCard.stampcard_id)}
                  >
                    特典と交換する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
      {loading && <Loading />}
    </div>
  );
}

export default StampCard;