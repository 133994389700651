import React, { useEffect, useState, useCallback } from 'react';
// import { useHistory } from 'react-router-dom';
// import constClass from '../../Constants/Constants';
// import Common from '../Common/common';
// import Popover from "react-popover";
// import CopyImg from '../Images/copy.svg';
// import util from 'util';
import axios from 'axios';
// import Loading from '../Loading/Loading';

const StampScan = ({ siteId, liffAccessToken, historyBack, setting }) => {

  const [message, setMessage] = useState('');
  const [countOrder, setCountOrder] = useState(null);

  const refreshData = useCallback(async () => {
    const params = {
      site_id: siteId,
      line_id: liffAccessToken
    }
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/waiting/search`, params);
    const order_count = result.data;
    if (order_count) {
      if (order_count.open_data && order_count.open_data.open_wait_flg === '2') {
        // 受け付けていない状態（すぐにご入店いただけます　ご来店ありがとうございます）
        setMessage('現在の待ち組数はありません');
      } else {
        if (order_count.wait_count && order_count.wait_count.length > 0) {
          const wait_count = order_count.wait_count[0];
          setCountOrder(wait_count.count_order);
        }
      }
    } else {
      setMessage('エラーが発生しました')
    }
  }, [siteId, liffAccessToken]);

  // useEffect(() => {
  //   var intervalId;
  //   function fetchData() {
  //     refreshData();
  //     intervalId = setInterval(() => {
  //       refreshData();
  //     }, 10000);
  //   }
  //   fetchData();
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [refreshData]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <div id="couponList" className="px-0-env">
      <div className="row mx-0 mt-2">
        <div className="col mx-3 my-0 py-1 px-0 text-left bg-white">
          <div className="mt-2 px-2 py-0 text-center">
            <div id="container" className="container">
              <div className="container-fluid section-white m-0 py-2 vh-50 overflow-auto">
                <div className="row mx-0 px-3-env text-center form-group">
                  <div className="col px-0 align-self-center">
                    <span>レストランReilly 待ち状況</span>
                  </div>
                </div>
                {countOrder !== null &&
                  <React.Fragment>
                    <div className="row mx-0 px-3-env mt-3 mb-2 text-center">
                      <div className="col"></div>
                      <div className="col-auto px-0 align-self-end text-right pr-2">
                        現在の待ち組数
                      </div>
                      <div className="col-auto px-0 align-self-end text-left pl-2">
                        <h5 className="mb-0 d-inline font-weight-bold">{countOrder}</h5>
                        <span className="d-inline small pl-1">組</span>
                      </div>
                      <div className="col"></div>
                    </div>
                  </React.Fragment>
                }
                <div className="row mx-0 px-3-env mt-2 mb-3 text-center">
                  <div className="col px-0 align-self-center">{message}</div>
                </div>
                <div className="row mx-0 px-1 mt-2 mb-1 text-left">
                  <div className="col px-0 align-self-center small">
                    ※このページからご予約はできません。<br />
                    順番待ちされる場合は、レストラン店頭にてお手続きお願いいたします。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row mx-0 mt-2">
        <div className="col mx-3 my-0 py-1 px-0 text-center">
          <button
            className="btn btn-enable w-100 py-2"
            onClick={() => { historyBack(); }}>
            戻る
          </button>
        </div>
      </div> */}
      {!!setting.DISPLAYWAITING_BANNER &&
        <div className={`mx-3 my-3 p-1 text-center`}>
          {!!setting.DISPLAYWAITING_BANNER_URL &&
            <a href={setting.DISPLAYWAITING_BANNER_URL} target="_blank" rel="noreferrer">
              {!!setting.DISPLAYWAITING_BANNER_TEXT &&
                <div className="mb-1">
                  <u>{setting.DISPLAYWAITING_BANNER_TEXT}</u>
                </div>
              }
              <div className="">
                <img src={`/${siteId}/${setting.DISPLAYWAITING_BANNER}`} className="w-100" alt={"バナー"} />
              </div>
            </a>
          }
          {!setting.DISPLAYWAITING_BANNER_URL &&
            <div className="">
              <img src={`/${siteId}/${setting.DISPLAYWAITING_BANNER}`} className="w-100" alt={"バナー"} />
            </div>
          }
        </div>
      }
    </div>
  );
}

export default StampScan;