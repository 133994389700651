import React, { useState, useEffect, useCallback } from 'react';
// import constClass from '../../Constants/Constants';
// import Common from '../Common/common';
// import moment from 'moment';
import Modal from 'react-modal';
import axios from 'axios';
import CloseImg from '../Images/close.svg';

const PostalCodeSearch = ({ siteId, openFlag, onClose, onSuccess }) => {
  const [pref, setPref] = useState('');
  const [city, setCity] = useState('');
  const [town, setTown] = useState('');
  const [code, setCode] = useState('');
  const [prefList, setPrefList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [townList, setTownList] = useState([]);

  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '0.5rem',
      right: '0.5rem',
      top: '10vw',
      bottom: 'auto',
      marginTop: '0.5rem',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto"
    }
  };

  const searchAddress = useCallback(async (pref, city) => {
    try {
      return (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/address/search/?pref=${pref}&city=${city}`)).data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }, []);

  //バックエンドサーバから住所を取得
  const searchPref = useCallback(async () => {
    //県リスト設定
    setPrefList(await searchAddress('', ''));
  }, [searchAddress]);

  const changePref = async (e) => {
    const value = e.target.value;
    setPref(value);

    setCity('');
    setCityList(await searchAddress(value, ''));
    setTown('');
    setTownList([]);
    setCode('');
  }

  const changeCity = async (e) => {
    const value = e.target.value;
    setCity(value);

    setTown('');
    setTownList(await searchAddress(pref, value));
    setCode('');
  }

  const changeTown = async (e) => {
    const value = e.target.value;
    setTown(value);
    setCode(townList.find(t => t.name === value).code);
  }

  const resetState = () => {
    setPref('');
    setCity('');
    setTown('');
    setCode('');
    setCityList([]);
    setTownList([]);
  }

  const submit = async () => {
    await onSuccess(code);
    resetState();
  }
  
  const closeModal = () => {
    resetState();
    onClose();
  }

  useEffect(() => {
    searchPref();
  }, [searchPref]);

  return (
    <Modal isOpen={openFlag} style={modalStyle} onRequestClose={() => closeModal()}>
      <div className="postalCodeSearch container-fluid section-white m-0 py-2 vh-50 overflow-auto">
        <div className="row mx-0 px-3-env text-left form-group">
          <div className="col px-0 align-self-center">
            <h4>郵便番号検索</h4>
          </div>
          <div className="col-auto px-0 align-self-start">
            <div className="text-right px-0" onClick={() => closeModal()}><img className="icon" src={CloseImg} alt="閉じる" /></div>
          </div>
        </div>
        {prefList.length > 0 &&
          <div className="row mx-0 px-3-env py-1 text-center">
            <div className="col px-0 align-self-center">
              <select className="custom-select w-100 text-center" value={pref} onChange={changePref}>
                <option value={''} key={''}>-- 都道府県を選択 --</option>
                {prefList.map((address) =>
                  <option value={address.name} key={address.name}>{address.name}</option>
                )}
              </select>
            </div>
          </div>
        }
        {cityList.length > 0 &&
          <div className="row mx-0 px-3-env py-1 text-center">
            <div className="col px-0 align-self-center">
              <select className="custom-select w-100 text-center" value={city} onChange={changeCity}>
                <option value={''} key={''}>-- 市区町村を選択 --</option>
                {cityList.map((address) =>
                  <option value={address.name} key={address.name}>{address.name}</option>
                )}
              </select>
            </div>
          </div>
        }
        {townList.length > 0 &&
          <div className="row mx-0 px-3-env py-1 text-center">
            <div className="col px-0 align-self-center">
              <select className="custom-select w-100 text-center" value={town} onChange={changeTown}>
                <option value={''} key={''}>-- 町域を選択 --</option>
                {townList.map((address) =>
                  <option value={address.name} key={address.name}>{address.name}</option>
                )}
              </select>
            </div>
          </div>
        }
        {!!code &&
          <div className="row mx-0 px-3-env py-2 text-center">
            <div className="col px-0 align-self-center">
              <h4>{code}</h4>
            </div>
          </div>
        }
        {!!code &&
          <div className="row mx-0 px-3-env pb-2">
            <div className="col-12 text-center p-0">
              <button
                disabled={!code}
                className="btn-lg btn-active w-100 py-2"
                onClick={() => { submit() }}>
                決定
              </button>
            </div>
          </div>
        }
        <div className="row mx-0 px-3-env pt-2 pb-2">
          <div className="col-12 text-center p-0">
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PostalCodeSearch;