import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import Select from 'react-select';
import { useParams, generatePath } from 'react-router';
import Common from '../Common/common';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import InfiniteScroll from "react-infinite-scroller";
registerLocale('ja', ja);

const pageCount = 100;

const Customer = (props) => {
  const { siteId } = useParams();
  const { user, setting } = props;
  const [customerData, setCustomerData] = useState(null);
  const [customerDetailMaster, setCustomerDetailMaster] = useState(null);
  const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  const [condition, setCondition] = useState({});
  const [searchFlag, setSearchFlag] = useState(true);
  // const [errMsg, setErrMsg] = useState({ postalCode: '' });
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  // const [csvDetailData, setCsvDetailData] = useState(null);
  // const [csvTicketData, setCsvTicketData] = useState(null);
  // const [csvTicketDetailData, setCsvTicketDetailData] = useState(null);
  const [lineTextData, setLineTextData] = useState(null);
  const [lockData, setLockData] = useState(false);
  // const [couponMonth, setCouponMonth] = useState(Common.getMonthString());
  const [selectedCondition, setSelectedCondition] = useState([]);
  const { addToast } = useToasts();
  const maxYear = new Date().getFullYear();
  const [page, setPage] = useState(0);
  const [stampMaster, setStampMaster] = useState(null);

  const createSearchParams = useCallback((master) => {
    var reg_params = {
      site_id: siteId,
      operator: "and",
      where: [{
        site_id: siteId
      }],
      detail_where: []
    }
    Common.inputList(setting, master).filter(i => Object.keys(condition).includes(i.entry_type)).forEach(i => {
      const detail = i.detail_data.find(() => true);
      const where = i.data ? reg_params.detail_where : reg_params.where;
      if (detail.input_type === 'id') {
        if (condition[i.entry_type]) { // 入力値がtruthyの場合のみ
          where.push({ [i.entry_type]: Number(condition[i.entry_type]) });
        }
      } else if (detail.input_type === 'radio') {
        if (condition[i.entry_type].length > 0) {
          where.push({
            "operator": "in",
            "attr": i.entry_type,
            "val": condition[i.entry_type].map(item => item.value)
          });
        }
      } else if (detail.input_type === 'date-y') {
        if (condition[i.entry_type].start) {
          where.push({
            "operator": "gte",
            "attr": detail.entry_type,
            "val": condition[i.entry_type].start + '0000'
          });
        }
        if (condition[i.entry_type].end) {
          where.push({
            "operator": "lte",
            "attr": detail.entry_type,
            "val": condition[i.entry_type].end + '9999'
          });
        }
      } else if (detail.input_type === 'date-m') {
        if (condition[i.entry_type].length > 0) {
          where.push({
            "operator": "literal",
            "literal": `substring(${detail.entry_type}, 5, 2) in (${condition[i.entry_type].map(item => '\'' + item.value + '\'').join()})`
          });
        }
      } else if (detail.input_type === 'date-d') {
        if (condition[i.entry_type].length > 0) {
          where.push({
            "operator": "literal",
            "literal": `substring(${detail.entry_type}, 7, 2) in (${condition[i.entry_type].map(item => '\'' + item.value + '\'').join()})`
          });
        }
      } else if (detail.input_type === 'datetime') {
        if (condition[i.entry_type].start) {
          where.push({
            "operator": "gte",
            "attr": detail.entry_type,
            "val": condition[i.entry_type].start
          });
        }
        if (condition[i.entry_type].end) {
          where.push({
            "operator": "lt",
            "attr": detail.entry_type,
            "val": moment(condition[i.entry_type].end).add(1, 'days')
          });
        }
      } else {
        // 部分一致
        where.push({
          "operator": "substring",
          "attr": i.entry_type,
          "val": condition[i.entry_type]
        });
      }
    });
    return reg_params;
  }, [siteId, condition, setting]);

  const createCsvData = useCallback((data, master, stamp) => {
    const list = [];
    const head = Common.inputList(setting, master).map(i => ({ label: i.entry_type_short_name, key: i.entry_type }));
    head.push({ label: "LINE_ID", key: "line_id" });
    if (Common.getFunctionEnabled(setting, 'STAMPCARD') && stamp) {
      stamp.forEach(s =>
          head.push({ label: s.stampcard_name, key: "stamp_" + s.stampcard_id})
      )
      head.push({ label: "最終来館日", key: "stamp_date"})
    }
    data.forEach(c => {
      var customer = { line_id: c.line_id };
      Common.inputList(setting, master).forEach(i => {
        customer[i.entry_type] = renderData(c, i);
      });
      if (Common.getFunctionEnabled(setting, 'STAMPCARD') && stamp) {
        stamp.forEach(s => {
          customer["stamp_" + s.stampcard_id] = c.t_stamp ? c.t_stamp.filter(cs => cs.stampcard_id === s.stampcard_id).reduce((prev, cs) => prev + cs.point, 0) : 0
        })
        customer["stamp_date"] = (c.t_stamp && c.t_stamp.length > 0) ? moment(c.t_stamp.reduce((prev, cs) => prev < cs.stamp_date ? cs.stamp_date : prev, '')).format("YYYY/MM/DD") : ''
      }
      list.push(customer);
    });

    setCsvData({ header: head, list: list });
  }, [setting]);

  // const createCsvDetailData = useCallback((data, master, cData) => {
  //   const list = [];
  //   const head = Common.inputList(setting, master).map(i => ({ label: i.entry_type_short_name, key: i.entry_type }));
  //   head.push({ label: "LINE_ID", key: "line_id" });
  //   head.push({ label: "利用年月", key: "coupon_month" });
  //   cData.sort((a, b) => a.coupon_id - b.coupon_id).forEach(c => {
  //     head.push({ label: c.coupon_name + " コード", key: "coupon_code_" + c.coupon_id });
  //     head.push({ label: c.coupon_name + " 使用日時", key: "use_date_" + c.coupon_id });
  //   });
  //   data.forEach(c => {
  //     // // 利用年月で重複除外した配列取得
  //     // const coupon_month_list = [...new Set(c.m_coupon_code.map(cc => cc.coupon_month))];
  //     // // ソートして利用年月ごとに1行生成
  //     // coupon_month_list.sort((a, b) => Number(a) - Number(b)).forEach(month => {
  //     const customer = { line_id: c.line_id };
  //     Common.inputList(setting, master).forEach(i => {
  //       customer[i.entry_type] = renderData(c, i);
  //     });
  //     customer.coupon_month = c.m_coupon_code.length > 0 ? c.m_coupon_code[0].coupon_month : '';
  //     // クーポン列追加
  //     cData.sort((a, b) => a.coupon_id - b.coupon_id).forEach(coupon => {
  //       // const coupon_code_data = c.m_coupon_code.find(cc => cc.coupon_id === coupon.coupon_id && cc.coupon_month === month);
  //       const coupon_code_data = c.m_coupon_code.find(cc => cc.coupon_id === coupon.coupon_id);
  //       customer["coupon_code_" + coupon.coupon_id] = coupon_code_data ? coupon_code_data.coupon_code : '';
  //       customer["use_date_" + coupon.coupon_id] = coupon_code_data ? (coupon_code_data.use_date ? moment(coupon_code_data.use_date).format("YYYY/MM/DD HH:mm:ss") : '') : '';
  //     });
  //     list.push(customer);
  //     // })
  //   });

  //   setCsvDetailData({ header: head, list: list });
  // }, [setting]);

  // const createCsvTicketData = useCallback((data, master, tData) => {
  //   const list = [];
  //   const head = Common.inputList(setting, master).map(i => ({ label: i.entry_type_short_name, key: i.entry_type }));
  //   head.push({ label: "LINE_ID", key: "line_id" });
  //   head.push({ label: "利用年月", key: "ticket_month" });
  //   tData.sort((a, b) => a.ticket_id - b.ticket_id).forEach(t => {
  //     head.push({ label: t.ticket_name + " 最終使用日時", key: "use_date_" + t.ticket_id });
  //   });
  //   data.forEach(c => {
  //     // // 利用年月で重複除外した配列取得
  //     // const coupon_month_list = [...new Set(c.m_coupon_code.map(cc => cc.coupon_month))];
  //     // // ソートして利用年月ごとに1行生成
  //     // coupon_month_list.sort((a, b) => Number(a) - Number(b)).forEach(month => {
  //     const customer = { line_id: c.line_id };
  //     Common.inputList(setting, master).forEach(i => {
  //       customer[i.entry_type] = renderData(c, i);
  //     });
  //     customer.ticket_month = c.t_ticket_use.length > 0 ? c.t_ticket_use[0].ticket_month : '';
  //     // クーポン列追加
  //     tData.sort((a, b) => a.ticket_id - b.ticket_id).forEach(ticket => {
  //       const ticket_use_data = c.t_ticket_use.sort((a, b) => moment(b.use_date) - moment(a.use_date)).find(tu => tu.ticket_id === ticket.ticket_id);
  //       customer["use_date_" + ticket.ticket_id] = ticket_use_data ? (ticket_use_data.use_date ? moment(ticket_use_data.use_date).format("YYYY/MM/DD HH:mm:ss") : '') : '';
  //     });
  //     list.push(customer);
  //     // })
  //   });

  //   setCsvTicketData({ header: head, list: list });
  // }, [setting]);

  // const createCsvTicketDetailData = useCallback((data, master, tData) => {
  //   const list = [];
  //   const head = Common.inputList(setting, master).map(i => ({ label: i.entry_type_short_name, key: i.entry_type }));
  //   head.push({ label: "LINE_ID", key: "line_id" });
  //   head.push({ label: "利用年月", key: "ticket_month" });
  //   head.push({ label: "チケットID", key: "ticket_id" });
  //   head.push({ label: "チケット名", key: "ticket_name" });
  //   head.push({ label: "チケット使用回数", key: "ticket_times" });
  //   head.push({ label: "使用日時", key: "use_date" });
  //   data.forEach(c => {
  //     const customer = { line_id: c.line_id };
  //     Common.inputList(setting, master).forEach(i => {
  //       customer[i.entry_type] = renderData(c, i);
  //     });
  //     c.t_ticket_use.sort((a, b) => a.ticket_id - b.ticket_id === 0 ? a.ticket_times - b.ticket_times : a.ticket_id - b.ticket_id).forEach(t => {
  //       const use = {
  //         ...customer,
  //         ticket_month: t.ticket_month,
  //         ticket_id: t.ticket_id,
  //         ticket_name: tData.find(ticket => ticket.ticket_id === t.ticket_id) ? tData.find(ticket => ticket.ticket_id === t.ticket_id).ticket_name : '',
  //         ticket_times: t.ticket_times,
  //         use_date: t.use_date ? moment(t.use_date).format("YYYY/MM/DD HH:mm:ss") : '',
  //       }
  //       list.push(use);
  //     });
  //     // })
  //   });

  //   setCsvTicketDetailData({ header: head, list: list });
  // }, [setting]);

  const refreshCustomer = useCallback(async () => {
    if (setting) {
      // useCallback対応のため検索ボタンを押したときのみ実行
      if (searchFlag) {
        const jwt = localStorage.getItem('jwt');

        // お客様詳細マスタ
        const master = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customerdetail/${siteId}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })).data;
        setCustomerDetailMaster(master);
        const reg_params = createSearchParams(master);
        // お客様データ
        const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search_backyard/`, reg_params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        setCustomerData(data);
        // スタンプカードマスタ
        if (Common.getFunctionEnabled(setting, 'STAMPCARD')) {
          var stamp = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/${siteId}`, {
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          })).data;
          setStampMaster(stamp);
        }
        createCsvData(data, master, stamp);
        createLineTextData(data);
      }
      setSearchFlag(false);
    }
  }, [searchFlag, createSearchParams, createCsvData, setting, siteId]);

  // const refreshCoupon = useCallback(async () => {
  //   if (setting && customerDetailMaster && (Common.getFunctionEnabled(setting, 'COUPON') || Common.getFunctionEnabled(setting, 'TICKET'))) {
  //     if (couponMonth.length === 6) {
  //       const jwt = localStorage.getItem('jwt');
  //       // 明細CSV用クーポンマスタ
  //       const couponPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/coupon/${siteId}`, {
  //         headers: {
  //           Authorization: `Bearer ${jwt}`
  //         }
  //       });
  //       // 明細CSV用チケットマスタ
  //       const ticketPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket/${siteId}`, {
  //         headers: {
  //           Authorization: `Bearer ${jwt}`,
  //         }
  //       });
  //       // 明細用お客様データ
  //       console.log(customerDetailMaster);
  //       const reg_params = createSearchParams(customerDetailMaster);
  //       const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/search_backyard/${couponMonth}`, reg_params, {
  //         headers: {
  //           Authorization: `Bearer ${jwt}`,
  //         }
  //       })).data;
  //       // クーポンマスタ
  //       const cData = (await couponPromise).data;
  //       const tData = (await ticketPromise).data;
  //       createCsvDetailData(data, customerDetailMaster, cData);
  //       createCsvTicketData(data, customerDetailMaster, tData);
  //       createCsvTicketDetailData(data, customerDetailMaster, tData);
  //     } else {
  //       setCsvDetailData(null);
  //       setCsvTicketData(null);
  //       setCsvTicketDetailData(null);
  //     }
  //   }
  // }, [couponMonth, createSearchParams, createCsvDetailData, createCsvTicketData, createCsvTicketDetailData, siteId, setting, customerDetailMaster]);

  const handleHeaderClick = (column, detail) => {
    var _sortOrder = sortOrder;
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, detail, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, detail, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    setSortOrder(_sortOrder);
    var data = customerData.slice();
    data.sort((a, b) => sortData(a, b));
    setCustomerData(data);
  }

  /* 退会処理 */
  const handleCancelClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`お客様No[${data.customer_id}]を退会済みにします。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      status: constClass.STATUS.CCL,
      // payment_status: constClass.PAYMENT_STATUS.CCL, // 決済停止中
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/status/${data.customer_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshCustomer();
      setLockData(false);
    }
  }

  /* 退会取消処理 */
  const handleRegisterClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`お客様ID[${data.customer_id}]の退会を取り消します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      status: constClass.STATUS.REG,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/status/${data.customer_id}`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshCustomer();
      setLockData(false);
    }
  }

  const handleChangeSelectedCondition = (data) => {
    var temp = { ...condition };
    // 追加された項目の初期値を設定する
    data.forEach(d => {
      const entry = Common.inputList(setting, customerDetailMaster).find(i => i.entry_type === d.value);
      const detail = entry.detail_data.find(() => true);
      if (detail.input_type === 'radio' || detail.input_type === 'date-m' || detail.input_type === 'date-d') {
        temp = { [entry.entry_type]: [], ...condition };
      } else if (detail.input_type === 'date-y' || detail.input_type === 'datetime') {
        temp = { [entry.entry_type]: { start: '', end: '' }, ...condition };
      } else {
        temp = { [entry.entry_type]: '', ...condition };
      }
    });
    // 削除された項目を検索条件から外す
    Object.keys(condition).filter(c => !data.map(d => d.value).includes(c)).forEach(c => {
      delete temp[c];
    })
    setCondition(temp);
    setSelectedCondition(data);
  }

  const handleSelect = (column) => (data) => {
    if (data.length <= 0) {
      setCondition({ ...condition, [column]: [] });
    } else {
      setCondition({ ...condition, [column]: data });
    }
  }

  const handleChangeYear = (range) => (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setCondition({ ...condition, [name]: { ...condition[name], [range]: value } });
  }

  const handleChangeDate = (name, values) => {
    const [start, end] = values;
    setCondition({ ...condition, [name]: { start: start, end: end } });
  }

  const handleChangeCondition = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setCondition({ ...condition, [name]: value });
    // setErrMsg({ ...errMsg, [name]: validator(name, value) });
  }

  // const validator = (name, value) => {
  //   switch (name) {
  //     case 'birth-y':
  //       return yearValidation(value);
  //     case 'postalCode':
  //       return postalCodeValidation(value);
  //     default:
  //       return '';
  //   }
  // }

  // const yearValidation = (value) => {
  //   if (value === '') return '';
  //   const regex = /^[0-9]{4}$/;
  //   if (!regex.test(value)) return '※正しい形式で年を入力してください';
  //   return '';
  // }

  // const postalCodeValidation = (value) => {
  //   if (value === '') return '';
  //   const regex = /^[0-9]{0,7}$/;
  //   if (!regex.test(value)) return '※正しい形式で郵便番号を入力してください';
  //   return '';
  // }

  const sortData = (a, b) => {
    if (sortOrder.length <= 0) { // 初期値はお客様ID
      return Number(a.customer_id) - Number(b.customer_id);
    }
    for (var i = 0; i < sortOrder.length; i++) {
      const item = sortOrder[i];
      const detail = item.detail.find(() => true);
      if (detail.input_type === 'radio') {
        const aData = item.detail.find(d => d.entry_data === a[item.column]) ? item.detail.find(d => d.entry_data === a[item.column]).entry_seq : 0;
        const bData = item.detail.find(d => d.entry_data === b[item.column]) ? item.detail.find(d => d.entry_data === b[item.column]).entry_seq : 0;
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else if (detail.input_type === 'id') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else if (['date-y', 'date-m', 'date-d'].includes(detail.input_type)) {
        const start = detail.input_type === 'date-y' ? 0 : detail.input_type === 'date-m' ? 4 : 6;
        const end = detail.input_type === 'date-y' ? 4 : detail.input_type === 'date-m' ? 6 : 8;
        const aData = Number(a[detail.entry_type] ? a[detail.entry_type].slice(start, end) : 0);
        const bData = Number(b[detail.entry_type] ? b[detail.entry_type].slice(start, end) : 0);
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }

  const createLineTextData = (data) => {
    const list = data.map(c => [c.line_id]);
    setLineTextData({ list: list });
  }

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)} key={column}>
      {sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  const renderCancelButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1`}
        disabled={lockData}
        onClick={() => handleCancelClick(data)}>
        退会
      </button>
    )
  }

  const renderRegisterButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1 px-0`}
        disabled={lockData}
        onClick={() => handleRegisterClick(data)}>
        退会取消
      </button>
    )
  }

  const renderData = (data, entry) => {
    const detail = entry.detail_data.find(() => true);
    const record = entry.data ? Common.getCustomerDetailObject(data[entry.data]) : data;
    if (detail.input_type === 'radio') {
      return entry.detail_data.find(d => d.entry_data === record[entry.entry_type]) ? entry.detail_data.find(d => d.entry_data === record[entry.entry_type]).entry_name : '';
    } else if (detail.input_type === 'datetime') {
      return moment(record[entry.entry_type]).isValid() ? moment(record[entry.entry_type]).format("YYYY/MM/DD HH:mm:ss") : '';
    } else if (detail.input_type === 'date') {
      return Common.getDateObject(record[entry.entry_type]).str;
    } else if (detail.input_type === 'date-y') {
      // ymd付きが指定された場合はdetail.entry_typeから値を取得
      return Common.getDateObject(record[detail.entry_type]).year || '';
    } else if (detail.input_type === 'date-m') {
      return Common.getDateObject(record[detail.entry_type]).month || '';
    } else if (detail.input_type === 'date-d') {
      return Common.getDateObject(record[detail.entry_type]).date || '';
    } else {
      return record[entry.entry_type];
    }
  }

  useEffect(() => {
    refreshCustomer();
  }, [refreshCustomer]);

  // useEffect(() => {
  //   refreshCoupon();
  // }, [refreshCoupon]);

  return (
    <div className="container">
      <div className="row mx-0 my-2 px-1-env">
        <div className="col-2 px-0">
          {setting &&
            <Select
              styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
              options={Common.inputList(setting, customerDetailMaster).map(i => ({ value: i.entry_type, label: i.entry_type_short_name }))}
              isClearable={false}
              isMulti
              closeMenuOnSelect={false}
              value={selectedCondition}
              placeholder={'検索条件...'}
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              onChange={handleChangeSelectedCondition} />
          }
        </div>
        <div className="col-9">
          <div className="row my-1">
            {setting && selectedCondition.map(c => {
              const entry = Common.inputList(setting, customerDetailMaster).find(i => i.entry_type === c.value);
              const detail = entry.detail_data.find(() => true);
              return entry && <div className="col-4" key={c.value}>
                <div className="row">
                  <div className="col-auto pr-0 text-center align-self-center">
                    <span>{entry.entry_type_short_name}</span>
                  </div>
                  {detail.input_type === 'text' &&
                    <div className="col text-center">
                      <input className={`form-control`} type="text" id={entry.entry_type} name={entry.entry_type} value={condition[entry.entry_type]} onChange={handleChangeCondition}></input>
                    </div>
                  }
                  {detail.input_type === 'number' &&
                    <div className="col text-center">
                      <input className={`form-control`} type="number" id={entry.entry_type} name={entry.entry_type} value={condition[entry.entry_type]} onChange={handleChangeCondition}></input>
                    </div>
                  }
                  {detail.input_type === 'id' &&
                    <div className="col text-center">
                      <input className={`form-control`} type="number" id={entry.entry_type} name={entry.entry_type} value={condition[entry.entry_type]} onChange={handleChangeCondition}></input>
                    </div>
                  }
                  {detail.input_type === 'radio' &&
                    <div className="col text-center">
                      <Select
                        styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
                        options={entry.detail_data.map(d => ({ value: d.entry_data, label: d.entry_name }))}
                        isClearable={false}
                        isMulti
                        closeMenuOnSelect={false}
                        value={condition[entry.entry_type]}
                        onChange={handleSelect(entry.entry_type)} />
                    </div>
                  }
                  {detail.input_type === 'date-y' &&
                    <React.Fragment>
                      <div className="col pr-0 text-center">
                        <input className={`form-control`} type="number" id={entry.entry_type + '-start'} name={entry.entry_type} value={condition[entry.entry_type].start} onChange={handleChangeYear('start')} max={maxYear}></input>
                      </div>
                      <div className="col-auto px-0 text-center align-self-center">
                        <span>～</span>
                      </div>
                      <div className="col pl-0 text-center">
                        <input className={`form-control`} type="number" id={entry.entry_type + '-end'} name={entry.entry_type} value={condition[entry.entry_type].end} onChange={handleChangeYear('end')} max={maxYear}></input>
                      </div>
                    </React.Fragment>
                  }
                  {detail.input_type === 'date-m' &&
                    <div className="col text-center">
                      <Select
                        styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
                        options={[...Array(12)].map((_, i) => ({ value: ('00' + (i + 1)).slice(-2), label: i + 1 }))}
                        isClearable={false}
                        isMulti
                        closeMenuOnSelect={false}
                        value={condition[entry.entry_type]}
                        onChange={handleSelect(entry.entry_type)} />
                    </div>
                  }
                  {detail.input_type === 'date-d' &&
                    <div className="col text-center">
                      <Select
                        styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
                        options={[...Array(31)].map((_, i) => ({ value: ('00' + (i + 1)).slice(-2), label: i + 1 }))}
                        isClearable={false}
                        isMulti
                        closeMenuOnSelect={false}
                        value={condition[entry.entry_type]}
                        onChange={handleSelect(entry.entry_type)} />
                    </div>
                  }
                  {detail.input_type === 'datetime' &&
                    <div className="col text-center">
                      <DatePicker className="custom-select text-left border-textbox"
                        locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={entry.entry_type} name={entry.entry_type}
                        popperPlacement='bottom'
                        selected={condition[entry.entry_type].start}
                        onChange={value => handleChangeDate(entry.entry_type, value)}
                        startDate={condition[entry.entry_type].start}
                        endDate={condition[entry.entry_type].end}
                        placeholderText="- 選択 -"
                        selectsRange
                      />
                    </div>
                  }
                </div>
              </div>
            })}
          </div>
        </div>
        <div className="col-1 text-center">
          <button type="button"
            className={`btn btn-primary mx-1 w-100`}
            onClick={() => setSearchFlag(true)}>
            検索</button>
        </div>
      </div>
      {customerData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {customerData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-10 pl-0 text-left align-self-end">
              <div className="row mr-0">
                <div className="col-2 pr-2 align-self-center">
                  {customerData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          一覧表印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 align-self-center">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={"お客様一覧.csv"}>
                      一覧表CSV出力
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-2 align-self-center">
                  {lineTextData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={lineTextData.list} filename={"LINE通知用ユーザID一覧.csv"} enclosingCharacter={""}>
                      ユーザID出力
                    </CSVLink>
                  }
                </div>
                {/*                 
                {(Common.getFunctionEnabled(setting, 'COUPON') || Common.getFunctionEnabled(setting, 'TICKET')) &&
                  <div className="col-6 px-0 pt-1 border">
                    <div className="row mx-0 px-0">
                      <div className="col mx-2 px-0">
                        <input className={`form-control`} type="text" id="couponMonth" name="couponMonth" value={couponMonth} onChange={(e) => setCouponMonth(e.target.value)}></input>
                      </div>
                      {Common.getFunctionEnabled(setting, 'COUPON') &&
                        <div className="col mx-2 px-0">
                          {csvDetailData &&
                            <CSVLink className="btn btn-primary print-none mb-1 px-0 py-1 w-100" data={csvDetailData.list} headers={csvDetailData.header} filename={"クーポン明細.csv"}>
                              クーポンCSV
                            </CSVLink>
                          }
                        </div>
                      }
                      {Common.getFunctionEnabled(setting, 'TICKET') &&
                        <React.Fragment>
                          <div className="col mx-2 px-0">
                            {csvTicketData &&
                              <CSVLink className="btn btn-primary print-none mb-1 px-0 py-1 w-100" data={csvTicketData.list} headers={csvTicketData.header} filename={"チケット明細.csv"}>
                                チケットCSV
                              </CSVLink>
                            }
                          </div>
                          <div className="col mx-2 px-0">
                            {csvTicketDetailData &&
                              <CSVLink className="btn btn-primary print-none mb-1 px-0 py-1 w-100" data={csvTicketDetailData.list} headers={csvTicketDetailData.header} filename={"チケット履歴.csv"}>
                                チケット履歴
                              </CSVLink>
                            }
                          </div>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                } 
                */}
              </div>
            </div>
            <div className="col-2 px-2 align-self-center text-right border h-100">
              <div className="row">
                <div className="col-auto py-1 px-2 bg-lightwhite h-100">一覧表件数</div>
                <div className="col py-1">{customerData.length.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <InfiniteScroll
                loadMore={(p) => setPage(Number(p) * pageCount)}    //項目を読み込む際に処理するコールバック関数 
                hasMore={(customerData.length > page)}         //読み込みを行うかどうかの判定
                loader={<div key={-1}>{customerData.length}件中 {page + pageCount}件まで表示中（スクロールで読み込み）</div>}       //読み込み最中に表示する項目
              >
                <table className="table table-bordered table-striped" height="1">
                  <thead className={`table-info`}>
                    <tr>
                      {Common.inputList(setting, customerDetailMaster).map(i =>
                        <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick(i.entry_type, i.detail_data)} key={i.entry_type}>
                          {i.entry_type_short_name}
                        </th>
                      )}
                      {(Common.getFunctionEnabled(setting, 'STAMPCARD') && stampMaster) &&
                        <React.Fragment>
                          {stampMaster.map(s =>
                            <th className="text-center align-middle" key={s.stampcard_id}>
                              {s.stampcard_name}
                            </th>
                          )}
                          <th className="text-center align-middle">
                            最終来館日
                          </th>
                        </React.Fragment>
                      }
                      {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CANCEL')) &&
                        <th className="text-center align-middle">
                          処理
                        </th>
                      }
                    </tr>
                    <tr className="print-none">
                      {Common.inputList(setting, customerDetailMaster).map(i =>
                        renderSortTh(i.entry_type)
                      )}
                      {(Common.getFunctionEnabled(setting, 'STAMPCARD') && stampMaster) &&
                        <React.Fragment>
                          {stampMaster.map(s =>
                            <th key={s.stampcard_id}></th>
                          )}
                          <th></th>
                        </React.Fragment>
                      }
                      {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CANCEL')) && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {customerData.map((data, idx) => (
                      <tr key={data.customer_id}>
                        {Common.inputList(setting, customerDetailMaster).map(i => {
                          return <td className="text-center align-middle p-1" key={i.entry_type + data.customer_id}>
                            {i.entry_type === 'customer_id' && <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${data.customer_id}`}>{renderData(data, i)}</Link>}
                            {i.entry_type !== 'customer_id' && renderData(data, i)}
                          </td>
                        })}
                        {(Common.getFunctionEnabled(setting, 'STAMPCARD') && stampMaster) &&
                          <React.Fragment>
                            {stampMaster.map(s =>
                              <td className="text-center align-middle p-1" key={s.stampcard_id + '_' + data.customer_id}>
                                {data.t_stamp ? data.t_stamp.filter(c => c.stampcard_id === s.stampcard_id).reduce((prev, c) => prev + c.point, 0) : 0}
                              </td>
                            )}
                            <td className="text-center align-middle p-1">
                              {(data.t_stamp && data.t_stamp.length > 0) ? moment(data.t_stamp.reduce((prev, c) => prev < c.stamp_date ? c.stamp_date : prev, '')).format("YYYY/MM/DD") : ''}
                            </td>
                          </React.Fragment>
                        }
                        {/* 処理 */}
                        {(user.userClass === constClass.USER_CLASS.ADMIN && Common.getFunctionEnabled(setting, 'CANCEL')) &&
                          <td className="text-center align-middle p-1">
                            {data.status === constClass.STATUS.REG && renderCancelButton(data)}
                            {data.status === constClass.STATUS.CCL && renderRegisterButton(data)}
                          </td>
                        }
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Customer;