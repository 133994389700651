import React, { Component } from 'react';
import Liff from '../Components/Liff/Liff';
import queryString from 'query-string';
import axios from 'axios';
// import constClass from '../Constants/Constants';
// import VConsole from 'vconsole';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: props.match.params.siteId,
      liff_access_token: null,
      liff_user_id: null,
      channel_id: null,
      page: null,
      open_data: null,
      my_order: null,
      current_id: null,
      campaign_id: null,
      issue_count: null,
      stamp_uuid: null,
    };

    this.liff_init = this.liff_init.bind(this);
  }

  liff_init() {
    if (window.liff.isInClient() && window.liff.isLoggedIn()) {
      const accessToken = window.liff.getAccessToken();
      const decodedId = window.liff.getDecodedIDToken();
      this.setState({
        liff_access_token: accessToken,
        liff_user_id: decodedId.sub
      });
    } else {
      this.setState({
        liff_access_token: 'token_unusable',
        liff_user_id: 'test_user_id'
      });
    }
  }

  setPage(page) {
    this.setState({ page: page });
  }

  setCampaignId(campaign_id) {
    this.setState({ campaign_id: parseInt(campaign_id) });
  }

  setCurrentCoupon(current_id) {
    this.setState({ current_id: parseInt(current_id) });
  }

  setIssueCount(issue_count) {
    this.setState({ issue_count: parseInt(issue_count) });
  }

  setOpenData(open_data) {
    this.setState({ open_data: open_data });
  }

  setMyOrder(order) {
    this.setState({ my_order: order });
  }

  setStampUUID(uuid) {
    this.setState({ stamp_uuid: uuid });
  }

  async componentDidMount() {
    if (process.env.REACT_APP_ENV !== 'pro') {
      // new VConsole();
    }
    var qs = queryString.parse(this.props.location.search);
    // var liffqs = (qs["liff.state"] !== undefined ? queryString.parse(qs["liff.state"]) : null);
    this.setState({ channel_id: qs.channel, page: qs.page, current_id: parseInt(qs.current_id), campaign_id: parseInt(qs.campaign_id), issue_count: parseInt(qs.issue_count), stamp_uuid: qs.stamp_uuid });
    if (process.env.REACT_APP_ENV !== 'dev') {
      if (window.liff.id === null) {
        var liffId;
        const setting = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${this.state.siteId}`)).data;
        liffId = setting['LINE_LIFF_ID'];
        window.liff.init({ liffId: liffId });
      }
      window.liff.ready.then(this.liff_init);
    } else {
      this.liff_init();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var qs = queryString.parse(this.props.location.search);
      this.setState({ channel_id: qs.channel, page: qs.page, current_id: parseInt(qs.current_id), campaign_id: parseInt(qs.campaign_id), issue_count: parseInt(qs.issue_count), stamp_uuid: qs.stamp_uuid });
    }
  }

  render() {
    return (
      <Liff
        liff_access_token={this.state.liff_access_token}
        liff_user_id={this.state.liff_user_id}
        channel_id={this.state.channel_id}
        page={this.state.page}
        setPage={(page) => { this.setPage(page); }}
        campaign_id={this.state.campaign_id}
        setCampaignId={(campaign_id) => { this.setCampaignId(campaign_id); }}
        current_id={this.state.current_id}
        setCurrentCoupon={(current_id) => { this.setCurrentCoupon(current_id); }}
        issue_count={this.state.issue_count}
        setIssueCount={(issue_count) => { this.setIssueCount(issue_count); }}
        stamp_uuid={this.state.stamp_uuid}
        setStampUUID={(uuid) => { this.setStampUUID(uuid); }} />
    );
  }
}

export default App;