import React from 'react';
import spinner from '../Images/LINE_spinner_dark.svg';

function Loading() {
  return (
    <div className="loading">
      <img className="spinner" src={spinner} alt=""/>
    </div>
  );
}

export default Loading;