import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Select from 'react-select';

const Setting = (props) => {
  const { siteId } = useParams();
  const { user } = props;
  const history = useHistory();
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [settingData, setSettingData] = useState(null);
  const [settingDataOld, setSettingDataOld] = useState(null);
  const [settingDataError, setSettingDataError] = useState({});

  const refreshSetting = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, { site_id: siteId }, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    var data = result.filter(res => res.site_id === Number(siteId) && res.setting_name).map(item => ({ 
      ...item, 
      input_list: item.input_list ? JSON.parse(item.input_list) : null,
      deletefile: null,
      uploadfiletype: null,
      uploadfile: (item.input_list && JSON.parse(item.input_list).input_type === 'image') ? (item.setting_val ? `/${siteId}/${item.setting_val}` : null) : null,
    })); // setting_nameが含まれているもののみ設定可能
    // data.sort((a, b) => a.setting_name < b.setting_name ? -1 : 1); // サーバサイドソート
    setSettingData(data);
    setSettingDataOld(data);
  }, [siteId]);

  // 複数レコード　val値change (更新のみ)
  const handleChangeCell = (index, key) => (event) => {
    const _settingData = [...settingData];
    const isUpdate = !checkEqual(settingDataOld[index][key], event.target.value);
    _settingData[index] = { ..._settingData[index], [key]: event.target.value, isUpdate: isUpdate };
    setSettingData(_settingData);
    const _settingDataError = { ...settingDataError, [_settingData[index].setting_type]: validation(index, event.target.value) }
    setSettingDataError(_settingDataError)
  }
  const handleChangeSelect = (index, key, data) => {
    const _settingData = [...settingData]
    const isUpdate = !checkEqual(settingDataOld[index][key], data.value);
    _settingData[index] = { ..._settingData[index], [key]: data.value, isUpdate: isUpdate }
    setSettingData(_settingData);
  }

  //入力形式チェック
  const validation = (index, value) => {
    const _setting = settingData[index];
    if (!_setting.regex_pattern) return true;//設定がない場合はOK
    var regex = new RegExp(_setting.regex_pattern, _setting.regex_flags ? _setting.regex_flags : undefined);
    return (regex.test(value));
  }
  const checkEqual = (value1, value2) => {
    var val1 = (value1 === null || value1 === undefined) ? "" : value1.toString();
    var val2 = (value2 === null || value2 === undefined) ? "" : value2.toString();
    return (val1 === val2)
  }

  const handleUpdateClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 確認メッセージ
    if (!window.confirm(`設定マスタを更新します。よろしいですか？`)) {
      return;
    }

    // let res;
    try {
      setLockData(true);
      if (data.isUpdate === true) {
        let params = {
          setting_type: data.setting_type,
          setting_val: data.setting_val,
          uploadfile: data.uploadfiletype ? data.uploadfile : null,
          deletefile: data.deletefile,
        };

        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/setting/update/${props.match.params.siteId}/${data.setting_type}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
        await refreshSetting();
      };

    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setLockData(false);
    }

  }

  const handleChangeFile = (index) => (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      const _settingData = [...settingData];
      _settingData[index] = { ..._settingData[index], uploadfile: reader.result, uploadfiletype: value.type, deletefile: _settingData[index].setting_val, isUpdate: true };
      setSettingData(_settingData);
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = (index) => () => {
    const _settingData = [...settingData];
    _settingData[index] = { ..._settingData[index], uploadfile: null, uploadfiletype: null, deletefile: _settingData[index].setting_val, isUpdate: true };
    setSettingData(_settingData);
  }

  const updateButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || !data.isUpdate}
        className={`btn btn-primary mx-1 text-nowrap`}
        onClick={() => handleUpdateClick(data)}>
        更新
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshSetting();
    }
    return fetchData();
  }, [history, props.match.path, refreshSetting, user.userClass]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {settingData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {settingData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR.BUS}`}>
                <tr>
                  <td className="text-center">
                    No
                  </td>
                  <td className="text-center">
                    設定名称
                  </td>
                  <td className="text-center">
                    設定備考
                  </td>
                  <td className="text-center w-50">
                    設定値
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {settingData.map((data, idx) => (
                  <tr key={data.setting_type}>
                    {/* 設定名称 */}
                    <td className="text-center align-middle">
                      {data.setting_order}{data.setting_order_sub && '-' + data.setting_order_sub}
                    </td>
                    {/* 設定名称 */}
                    <td className="text-left align-middle">
                      {data.setting_name}
                    </td>
                    {/* 設定備考 */}
                    <td className="text-left align-middle text-prewrap">
                      {data.setting_note}
                    </td>
                    {/* 設定値 */}
                    <td className="text-right align-middle">
                      {(!data.input_list || !data.input_list.input_type) && (
                        <textarea rows={1} className="form-control" id={"setting_val" + data.setting_type} name="setting_val[]" value={data.setting_val || ""} onChange={handleChangeCell(idx, 'setting_val')}></textarea>
                      )}
                      {(data.input_list && data.input_list.input_type === 'radio') &&
                        <div className='row px-2'>
                          {data.input_list.options.map((item, optionidx) =>
                            <label className='px-2' key={optionidx}>
                              <input type="radio" className="mx-2" id={`setting_val_${data.setting_type}_${optionidx}`} name={`setting_val_${data.setting_type}`} onChange={handleChangeCell(idx, 'setting_val')} value={item.value} checked={checkEqual(item.value, data.setting_val)} />
                              {item.label}
                            </label>
                          )}
                        </div>
                      }
                      {(data.input_list && data.input_list.input_type === 'select') &&
                        <Select
                          name={`setting_val_${data.setting_type}`}
                          id={`setting_val_${data.setting_type}`}
                          defaultValue={data.input_list.options.find(r => r.value === data.setting_val) || ''}
                          label={data.setting_name}
                          options={data.input_list.options}
                          onChange={(e) => handleChangeSelect(idx, 'setting_val', e)}
                        />
                      }
                      {(data.input_list && data.input_list.input_type === 'image') &&
                        <React.Fragment>
                          {!(data.uploadfile) && <input type="file" className="form-control-file" name={`setting_val_${data.setting_type}`} id={`setting_val_${data.setting_type}`} value="" onChange={handleChangeFile(idx)} onClick={e => (e.target.value = null)} />}
                          {data.uploadfile && <object id={`uploadfileview_${data.setting_type}`} className="w-100" data={data.uploadfile} type={data.uploadfiletype}><img className="w-100" src={data.uploadfile} alt='uploadfile' /></object>}
                          {data.uploadfile && <input type="button" className="form-controll" name={`deletefile_${data.setting_type}`} id={`deletefile_${data.setting_type}`} onClick={handleDeleteFile(idx)} value="ファイル削除" />}
                        </React.Fragment>
                      }
                      {(data.setting_type in settingDataError && settingDataError[data.setting_type] !== true) && <div className='w-100 text-left text-danger'>入力内容が間違っています。</div>}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {updateButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Setting