import constClass from '../../Constants/Constants';
import Common from '../Common/common';
import Popover from "react-popover";
import CopyImg from '../Images/copy.svg';

const CouponList = ({ coupon_list, coupon_codes, couponClick, siteId, popover_message }) => {

  const getCouponCode = (id) => {
    return coupon_codes.find(c => c.coupon_id === id) ? coupon_codes.find(c => c.coupon_id === id) : {};
  }

  return (
    <div id="couponList" className="px-0-env">
      <div className="row mx-0">
        <div className="col mx-3 my-1 p-0 text-left bg-white">
          <div className="card-header p-2">
            以下に表示されているクーポンコード横の「copy」をタップし、各サービスのクーポンコード入力画面にてご登録ください。<br />
            クーポン有効期限：{new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + ((new Date().getMonth() + 1) === 10 ? '8日' : '1日')}～{(new Date().getMonth() + 1) + '月'}末日<br />
            ※ご利用にあたり、無料の範囲内でのご利用でも事前に決済情報の登録が必要な場合がございます。
          </div>
        </div>
      </div>
      {coupon_list && coupon_list.map(coupon => 
        <div key={coupon.coupon_id} className="mx-3 mt-1 mb-3 bg-white border">
          <div className="row mx-0 px-3-env pt-2 pb-1 card-header">
            <div className="col py-1 px-1 align-self-center text-center">
              <h4 className="p-0 m-0">{coupon.coupon_name}</h4>
              {coupon.coupon_summary && <span className="p-0 m-0">{coupon.coupon_summary}</span>}
            </div>
          </div>
          <div className="row mx-0 px-3-env pt-2 pb-1">
            {coupon.coupon_image_url &&
              <div className="col-4 py-1 px-1 align-self-center text-center">
                {!coupon.hp_url && <img className="w-100" src={`/${siteId}/${constClass.COUPON_IMAGE_DIR}${coupon.coupon_image_url}`} alt={coupon.coupon_name} />}
                {coupon.hp_url &&
                  <a href={Common.getExternalUrl(coupon.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                    <img className="w-100" src={`/${siteId}/${constClass.COUPON_IMAGE_DIR}${coupon.coupon_image_url}`} alt={coupon.coupon_name} />
                    <u>公式サイトへ</u>
                  </a>
                }
              </div>
            }
            <div className="col py-1 px-1 align-self-center">
              <div className="row">
                <div className="col">
                  <span className="p-0 m-0">{Common.getBrString(coupon.coupon_detail)}</span>
                </div>
              </div>
              <div className="row justify-content-between border rounded ml-1 mr-0 mt-2">
                <div className={`${coupon.copy_flag === constClass.FLAG.ON ? 'col-9' : 'col py-2'} align-self-center text-wrap px-1`}>
                  <span className="mb-0 selectable">{getCouponCode(coupon.coupon_id).coupon_code}</span>
                </div>
                {coupon.copy_flag === constClass.FLAG.ON &&
                  <div className="col-3 align-self-center text-right pl-1 pr-0">
                    <Popover
                      isOpen={popover_message[coupon.coupon_id] !== undefined}
                      body={<div className="section-dark px-3">{popover_message[coupon.coupon_id]}</div>}>
                      <button
                        className="btn btn-enable py-0 px-1"
                        onClick={couponClick(coupon.coupon_id)}>
                        <img className="icon" src={CopyImg} alt="コピー" />
                        <br /><span className="small">COPY</span>
                      </button>
                    </Popover>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-3 px-3-env pt-2 pb-1">
            <div className="col">
              {coupon.app_url &&
                <div className="row px-0 my-2">
                  <div className="col">
                    <a href={Common.getExternalUrl(coupon.app_url)} target="_blank" rel="noreferrer">
                      <button
                        className="btn btn-enable w-100">
                        {coupon.app_text ? coupon.app_text : 'アプリをダウンロード'}
                      </button>
                    </a>
                  </div>
                </div>
              }
              {coupon.detail_url &&
                <div className="row px-0 my-2">
                  <div className="col">
                    <a href={Common.getExternalUrl(coupon.detail_url)} target="_blank" rel="noreferrer">
                      <button
                        className="btn btn-digitalcard w-100">
                        {coupon.detail_text ? coupon.detail_text : 'クーポンコード利用方法'}
                      </button>
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CouponList;