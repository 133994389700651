import React, { Component } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import Modal from 'react-modal';
import "react-accessible-accordion/dist/fancy-example.css";
import moment from 'moment';
import util from 'util';
import CloseImg from '../Images/close.svg';
import AarrowDownImg from '../Images/arrow-down.svg';
import LineLeftImg from '../Images/line-left-bottom.svg';
import LineRightImg from '../Images/line-right-bottom.svg';

Modal.setAppElement("#root");

class Pointcard extends Component {
  constructor(props) {
    super(props);
    const { campaign, my_customer } = this.props.condition;
    this.state = {
      campaign: campaign,
      my_customer: my_customer,
      my_entry: null,
      campaign_point_input_date: null,
      point_list: [],
      point_view_list: [],
      not_icon_image: {},
      icon_image: {},
      modal_flag: false,
      comments: null
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '5vw',
        bottom: 'auto',
        marginTop: '0.5rem',
        borderRadius: "0rem",
        padding: "0px",
        height: "80vh"
      }
    };

    this.refreshData = this.refreshData.bind(this);

  }
  componentDidMount() {
    this.refreshData();
    this.disableBounceScroll();
  }
  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        el = el.parentElement;
      }

      if (noScroll) {
        e.preventDefault();
      }

      touchY = moveY;
    }, { passive: false });
  }
  async refreshData() {

    const campaign = this.state.campaign;
    const my_customer = this.state.my_customer;

    const iconPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignicon/line/${campaign.site_id}/${campaign.campaign_id}`, { line_id: this.props.liff_access_token });
    const icon_list = (await iconPromise).data;
    const inputPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpointinput/line/${campaign.site_id}/${campaign.campaign_id}/`, { line_id: this.props.liff_access_token });
    const entryPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry/line/${campaign.site_id}/${campaign.campaign_id}/${my_customer.customer_id}/`, { line_id: this.props.liff_access_token });
    var my_entry = (await entryPromise).data;

    //1ポイント1件のデータに変換
    var not_icon_image = icon_list.find(x => x.icon_id.toString() === "0") ? icon_list.find(x => x.icon_id.toString() === "0") : { icon_image_url: '' };
    var def_icon_image = icon_list.find(x => x.icon_id.toString() === "1") ? icon_list.find(x => x.icon_id.toString() === "1") : { icon_image_url: '' };

    var point_list = (my_entry.status !== constClass.STATUS.REG) ? []
      : my_entry.entry_campaign_points.reduce((list, item) => {
        var p = [];
        var icon_image = icon_list.find(x => x.icon_id.toString() === item.icon_id.toString());
        for (var i = 0; i < item.point; i++) p.push({ icon_id: item.icon_id, use_date: item.use_date, icon_image: icon_image ? icon_image.icon_image_url : def_icon_image.icon_image_url });
        return list.concat(p);
      }, []);

    const view_point_count = point_list.length % campaign.entry_count === 0 ? Math.min(point_list.length, campaign.entry_count) : point_list.length % campaign.entry_count;
    const check_blank = new Array(campaign.entry_count - (point_list ? view_point_count : 0)).fill({ icon_image: not_icon_image.icon_image_url });
    var point_view_list = point_list.sort((a, b) => new Date(b.use_date) - new Date(a.use_date)).slice(0, view_point_count).concat(check_blank).sort((a, b) => new Date(a.use_date) - new Date(b.use_date));
    const campaign_point_input = (await inputPromise).data.find(x => moment(x.point_date) >= moment(campaign.campaign_start) && moment(x.point_date) <= moment(campaign.campaign_end));

    this.setState({
      campaign,
      my_entry,
      campaign_point_input_date: campaign_point_input ? campaign_point_input.point_date : null,
      point_list,
      point_view_list,
      not_icon_image,
      def_icon_image,
      comments: null,
      modal_flag: (this.props.page === constClass.ENTRY)
    });
  }

  getBrString = (str) => {
    const texts = str.split(/(\n)/).map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.match(/\n/) ? <br /> : item}
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  }
  cangeEnquete(event) {
    const target = event.target;
    const value = target.value;
    this.setState({ comments: value });
  }

  async submit() {
    if (this.state.comments === null) {

    } else {
      if (!window.confirm('入力された内容を送信します。よろしいですか？')) {
        return;
      }
      this.setState({ disabled: true });
      var data = { ...this.state.my_entry, comments: this.state.comments, line_id: this.props.liff_access_token }
      // API実行
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry/line`, data);

        await this.refreshData();
        this.setState({ disabled: false });
      } catch (err) {
        console.log(util.inspect(err));
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('エラーが発生しました。');
        }
        this.setState({ disabled: false });
      }

    }
  }
  renderCampaignEnd = () => {
    return (
      <div className="mx-3 my-3 bg-white vh-100 overflow-auto">
        <div className="row pt-2 mx-0 px-0">
          <div className="col mx-0 px-0 text-center">
            {this.state.point_list.length < this.state.campaign.entry_count &&
              <h4 className="text-danger font-weight-bold text-center">{this.state.point_list.length}ポイントのため、<br />応募できませんでした。</h4>
            }
            {this.state.point_list.length >= this.state.campaign.entry_count &&
              <h3 className="text-danger font-weight-bold text-center">{Math.floor(this.state.point_list.length / this.state.campaign.entry_count)} 口<br />応募完了!!</h3>
            }
          </div>
        </div>
        <div className="row pt-2 mx-0 px-0">
          <div className="col mx-0 px-0 text-center">
            <img className="w-100" src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${constClass.CAMPAIGN_IMAGE_LIST.CAMPAIGNEND}`} onError={e => e.target.style.display = 'none'} alt="" />
          </div>
        </div>
        {this.state.campaign_point_input_date !== null &&
          <div className="row mx-0 mb-3 pt-2 px-1">
            <div className="col px-0 text-right">
              ポイント反映日：{this.state.campaign_point_input_date.substr(0, 4) + '/' + this.state.campaign_point_input_date.substr(4, 2) + '/' + this.state.campaign_point_input_date.substr(6, 2)}
            </div>
          </div>
        }
        <div className="row pt-2 mx-0 px-0">
          <div className="col mx-0 px-0 text-center pb-3 ">
            <h4 className="text-blue font-weight-bold text-center">
              {(this.state.point_list.length >= this.state.campaign.entry_count && constClass.CAMPAIGN_MESSAGE.END_MESSAGE_1) && this.getBrString(constClass.CAMPAIGN_MESSAGE.END_MESSAGE_1)}
              {(this.state.point_list.length < this.state.campaign.entry_count && constClass.CAMPAIGN_MESSAGE.END_MESSAGE_2) && this.getBrString(constClass.CAMPAIGN_MESSAGE.END_MESSAGE_2)}
            </h4>
          </div>
        </div>

        {(this.state.campaign.campaign_end_enquete && this.state.my_entry && this.state.my_entry.comments === null) &&
          <div className="text-center my-3 pt-2 border-top border-blue border-2">
            <h4 className="text-blue font-weight-bold text-center">本キャンペーンに対するご意見・ご感想等ございましたらご記入ください。</h4>
            <textarea className="w-100" rows="5" onChange={e => this.cangeEnquete(e)}></textarea>
            <button className="w-50 btn btn-info" onClick={() => { this.submit() }}>登録</button>
          </div>
        }
      </div>
    )
  }
  renderPointList = () => {
    return (
      <div className="mx-3 my-3 bg-white vh-100 overflow-auto">
        <div className="row pt-2 mx-0 px-0">
          {this.state.point_view_list !== null && this.state.point_view_list.map((data, idx) => (
            <div className="pointicon px-0 text-center" key={idx}>
              {data.icon_image && <img src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${data.icon_image}`} onError={e => e.target.style.display = 'none'} alt="" />}
              {!data.icon_image && data.use_date && <span className="point-icon">★</span>}
              {!data.icon_image && !data.use_date && <span className="blank-icon">★</span>}
            </div>
          ))}
        </div>
        {this.state.campaign_point_input_date !== null &&
          <div className="row mx-0 mb-3 pt-2 px-1">
            <div className="col px-0 text-right">
              ポイント反映日：{this.state.campaign_point_input_date.substr(0, 4) + '/' + this.state.campaign_point_input_date.substr(4, 2) + '/' + this.state.campaign_point_input_date.substr(6, 2)}
            </div>
          </div>
        }
        {(this.state.point_list.length > 0 && (this.state.point_list.length % this.state.campaign.entry_count) === 0) &&
          <div>
            <div className="row mx-0 pt-2 px-0">
              <div className="col-12 py-0 my-0 text-center">
                <img src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${constClass.CAMPAIGN_IMAGE_LIST.POINTENTRY}`} onError={e => e.target.style.display = 'none'} alt="" />
              </div>
            </div>
            <div className="row mx-0 px-0 pb-2">
              <div className="col-12 align-top">
                <h3 className="w-100 py-0 my-0 text-center font-weight-bold text-blue">{constClass.CAMPAIGN_MESSAGE.ENTRY_MESSAGE_1}</h3>
              </div>
            </div>
            <div className="row mx-0 px-0 py-3 text-center border-top border-bottom border-danger border-2">
              <div className="w-100 text-danger">
                <h4 className="font-weight-bold">応募口数：{Math.floor(this.state.point_list.length / this.state.campaign.entry_count)}口目獲得！</h4>
              </div>
            </div>
            <div className="row mx-0 px-0 py-3 text-center">
              <div className="col text-blue">
                <div>{this.getBrString(constClass.CAMPAIGN_MESSAGE.ENTRY_MESSAGE_2)}</div>
              </div>
            </div>
          </div>
        }
        {(this.state.point_list.length === 0 || (this.state.point_list.length % this.state.campaign.entry_count) !== 0) &&
          <div>
            <div className="row mx-0 pt-2 px-0">
              <div className="col-5 align-top">
                <h4 className="w-100 py-0 my-0 text-center font-weight-bold text-blue">現在</h4>
                <div className="w-100 py-0 my-0 text-center font-weight-bold text-blue font-25vw">{(this.state.point_list.length % this.state.campaign.entry_count)}</div>
              </div>
              <div className="col-7">
                <img className="w-75" src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${constClass.CAMPAIGN_IMAGE_LIST.POINTCARD}`} onError={e => e.target.style.display = 'none'} alt="" />
              </div>
            </div>
            <div className="row my-2 mx-0 pt-2 px-0">
              <h4 className="w-100 text-center font-weight-bold text-blue mx-0 px-0">{this.state.campaign.point_name}{this.state.point_list.length > 0 ? "完了！！" : "スタート！"}</h4>
            </div>
            <div className="row mx-0 px-0 py-3 text-center border-top border-bottom border-danger border-2">
              <div className="w-100 text-danger">
                <h4 className="font-weight-bold">現在の応募口数：<span className="font-9vw">{this.state.point_list.length === 0 ? 0 : Math.floor(this.state.point_list.length / this.state.campaign.entry_count)}</span></h4>
              </div>
            </div>
          </div>
        }
        <div className="row mx-0 pt-3 x-0">
          <div className="col text-center">
            <button type="button" className="btn-lg  btn-danger border-radius-30 px-3"
              onClick={() => { this.props.history.push(`/${this.state.campaign.site_id}?page=${constClass.USER}&campaign_id=${this.state.campaign.campaign_id}`); }}
            >登録情報を変更する</button>
          </div>
        </div>
        <div className="row mx-0 pt-3 pb-5 px-0">
          <div className="col text-center">
            <button
              className="btn btn-link w-100"
              disabled={this.state.disabled}
              onClick={() => { this.props.history.push(`/${this.state.campaign.site_id}?page=${constClass.CONFIRM2}&campaign_id=${this.state.campaign.campaign_id}`); }}>
              キャンペーンの内容を確認する。
            </button>
          </div>
        </div>
      </div>
    );
  };
  renderModal() {
    return (
      <Modal isOpen={this.state.modal_flag} onRequestClose={() => { this.setState({ modal_flag: false }); this.props.history.push(`/${this.state.campaign.site_id}?page=${constClass.POINTLIST}&campaign_id=${this.state.campaign.campaign_id}`); }} style={this.modalStyle}>
        <div className="row mx-0 px-2">
          <div className="col text-right px-0" onClick={() => { this.setState({ modal_flag: false }); this.props.history.push(`/${this.state.campaign.site_id}?page=${constClass.POINTLIST}&campaign_id=${this.state.campaign.campaign_id}`); }}>
            <img className="icon" src={CloseImg} alt="閉じる" />
          </div>
        </div>
        <div className="row mx-0 px-3-env text-left">
          <div className="col px-0 align-self-center">
            <h4 className="pb-3 border-bottom border-blue border-2 text-blue text-center font-weight-bold">ご参加<br />ありがとうございます！</h4>
          </div>
        </div>
        <div>
          {this.state.not_icon_image &&
            <div className="row pt-2 mx-0 px-0">
              {(new Array(this.state.campaign.entry_count)).fill({ icon_image: this.state.not_icon_image.icon_image_url }).map((data, idx) => (
                <div className="pointicon px-0 text-center" key={idx}>
                  {data.icon_image && <img src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${data.icon_image}`} onError={e => e.target.style.display = 'none'} alt="" />}
                </div>
              ))}
            </div>
          }
          <div className="row mx-1 my-1">
            <div className="col-2 mx-0 px-0"><img className="w-100" src={LineLeftImg} alt="" /></div>
            <div className="col mx-0 px-0"><h5 className="mx-0 px-0 py-2 text-blue text-center font-weight-bold">対象商品1点購入で、<br />1ポイントがたまります！</h5></div>
            <div className="col-2 mx-0 px-0"><img className="w-100" src={LineRightImg} alt="" /></div>
          </div>
          <div className="row mx-1 my-1">
            <div className="w-100 mx-0 px-0 text-center"><img src={AarrowDownImg} alt="" /></div>
          </div>

          {this.state.def_icon_image &&
            <div className="row pt-2 mx-0 px-0">
              {(new Array(this.state.campaign.entry_count)).fill({ icon_image: this.state.def_icon_image.icon_image_url }).map((data, idx) => (
                <div className="pointicon px-0 text-center" key={idx}>
                  {data.icon_image && <img src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${data.icon_image}`} onError={e => e.target.style.display = 'none'} alt="" />}
                </div>
              ))}
            </div>
          }
          <div className="row pt-2 mx-0 px-2 text-center">
            <div className="w-100 text-center text-blue text-center font-weight-bold">10ポイントたまると・・・</div>
            <h4 className="w-100  text-center text-blue text-center font-weight-bold">レスキュー完了！</h4>
          </div>
          <div className="row pt-2 mx-0 mb-3">
            <h3 className="px-3 w-100 text-center text-blue text-center font-weight-bold">応募権1口獲得！</h3>
          </div>
        </div>
      </Modal>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.campaign === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
        {this.state.campaign !== null &&
          <div>
            {moment(this.state.campaign.campaign_end) < moment() && this.renderCampaignEnd()}
            {moment(this.state.campaign.campaign_end) >= moment() && this.renderPointList()}
          </div>
        }
        {this.renderModal()}
      </React.Fragment>
    )
  }
}

export default Pointcard;