import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import QRCode from "react-qr-code";

const Stampcard = (props) => {
  const { user, setting } = props;
  const { siteId } = useParams();
  const [stampcardData, setStampcardData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const qrRef = useRef(null);

  const [values, setValues] = useState({
    stampcard_id: null,
    stampcard_name: '',
    point_limit: 10,
    start_date: moment().format('YYYYMMDD'),
    end_date: '99991231',
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });

  const [stampValues, setStampValues] = useState({
    stamp_id: null,
    stamp_name: '',
    point: 1,
    stamp_uuid: null,
    stamp_lon: '',
    stamp_lat: '',
    start_date: moment().format('YYYYMMDD'),
    end_date: '99991231',
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null,
    get_times: 0
  });

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const stampcard = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/${siteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setStampcardData(stampcard);
  }, [siteId]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    const temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.stamp_achievementimage_url != null) temp.uploadfile = `/${siteId}/${constClass.STAMP_IMAGE_DIR + data.stamp_achievementimage_url}`;
    else temp.uploadfile = null;
    setValues(temp);
    handleStampResetClick(temp);
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      stampcard_id: null,
      stampcard_name: '',
      point_limit: 10,
      start_date: moment().format('YYYYMMDD'),
      end_date: '99991231',
    });
    handleStampResetClick();
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    console.log(data);
    const jwt = localStorage.getItem('jwt');
    if (!data.stampcard_name) {
      window.alert('スタンプカード名を入力してください。');
      return;
    }
    if (data.stampcard_name.length > 50) {
      window.alert('スタンプカード名は50文字以内で入力してください。');
      return;
    }
    if (!data.point_limit) {
      window.alert('ポイント上限を入力してください。');
      return;
    }
    if (isNaN(Number(data.point_limit))) {
      window.alert('ポイント上限は数字を入力してください。');
      return;
    }
    if (Number(data.point_limit) <= 0) {
      window.alert('ポイント上限は1以上を入力してください。');
      return;
    }
    if (!data.start_date) {
      window.alert('利用開始日を入力してください。');
      return;
    }
    if (!data.end_date) {
      window.alert('利用終了日を入力してください。');
      return;
    }
    const start_date_moment = moment(data.start_date, 'YYYYMMDD');
    if (!start_date_moment.isValid()) {
      window.alert('利用開始日を正しい形式で入力してください。');
      return;
    }
    const end_date_moment = moment(data.end_date, 'YYYYMMDD');
    if (!end_date_moment.isValid()) {
      window.alert('利用終了日を正しい形式で入力してください。');
      return;
    }

    var params = {...data};
    params.point_limit = Number(data.point_limit);
    params.start_date = start_date_moment.toDate();
    params.end_date = end_date_moment.toDate();
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;
    if (data.uploadfiletype === null) params.uploadfile = null;

    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/upsert/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  // /* DB削除 */
  // const handleDeleteClick = async (data) => {
  //   const jwt = localStorage.getItem('jwt');

  //   if (!window.confirm(`スタンプカード[${data.stampcard_id}:${data.stampcard_name}]を削除します。登録済みのスタンプも削除されます。よろしいですか？`)) {
  //     return;
  //   }
  //   const params = {
  //     site_id: siteId,
  //     stampcard_id: data.stampcard_id,
  //     upd_date: data.upd_date
  //   };
  //   let res;
  //   try {
  //     setLockData(true);
  //     res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/delete/`, params, {
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //       }
  //     });
  //   } catch (err) {
  //     if (err.response.data !== null) {
  //       addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast(err.response, { appearance: 'error', autoDismiss: true });
  //     }
  //   } finally {
  //     if (res.data.error) {
  //       addToast(res.data.message, { appearance: 'error', autoDismiss: true });
  //     } else {
  //       addToast('削除しました。', { appearance: 'success', autoDismiss: true });
  //       await refreshData();
  //     }
  //     setLockData(false);
  //     handleResetClick();
  //   }
  // }

  /* 編集ボタン押下 */
  const handleStampUpdateClick = (data) => {
    const temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.stamp_image_url != null) temp.uploadfile = `/${siteId}/${constClass.STAMP_IMAGE_DIR + data.stamp_image_url}`;
    else temp.uploadfile = null;
    temp.get_times = (temp.get_times !== null && temp.get_times !== undefined) ? temp.get_times : 0;
    setStampValues(temp);
  }

  /* RESETボタン押下 */
  const handleStampResetClick = (data) => {
    setStampValues({
      stamp_id: null,
      stamp_name: '',
      point: 1,
      stamp_uuid: null,
      stamp_lon: '',
      stamp_lat: '',
      start_date: data ? moment(data.start_date).format('YYYYMMDD') : moment().format('YYYYMMDD'),
      end_date: data ? moment(data.end_date).format('YYYYMMDD') : '99991231',
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null,
      get_times: 0,
    });
  }

  /* DB更新 */
  const handleStampRegistClick = async (data) => {
    console.log(data);
    const jwt = localStorage.getItem('jwt');
    if (!data.stamp_name) {
      window.alert('スタンプ名を入力してください。');
      return;
    }
    if (data.stamp_name.length > 50) {
      window.alert('スタンプ名は50文字以内で入力してください。');
      return;
    }
    if (!data.point) {
      window.alert('付与ポイントを入力してください。');
      return;
    }
    if (isNaN(Number(data.point))) {
      window.alert('付与ポイントは数字を入力してください。');
      return;
    }
    if (Number(data.point) <= 0) {
      window.alert('付与ポイントは1以上を入力してください。');
      return;
    }
    if (!data.uploadfile) {
      window.alert('スタンプ画像を選択してください。');
      return;
    }
    if (isNaN(Number(data.stamp_lat))) {
      window.alert('緯度は数字を入力してください。');
      return;
    }
    if (Number(data.stamp_lat) > 90 || Number(data.stamp_lat) < -90) {
      window.alert('緯度は90度以内で入力してください。');
      return;
    }
    if (isNaN(Number(data.stamp_lon))) {
      window.alert('経度は数字を入力してください。');
      return;
    }
    if (Number(data.stamp_lon) > 180 || Number(data.stamp_lon) < -180) {
      window.alert('経度は180度以内で入力してください。');
      return;
    }
    if ((data.stamp_lat !== '' && data.stamp_lat !== null) && (data.stamp_lon === '' || data.stamp_lon === null)) {
      window.alert('緯度を入力した場合は経度を入力してください。');
      return;
    }
    if ((data.stamp_lon !== '' && data.stamp_lon !== null) && (data.stamp_lat === '' || data.stamp_lat === null)) {
      window.alert('経度を入力した場合は緯度を入力してください。');
      return;
    }
    if (!data.start_date) {
      window.alert('付与開始日を入力してください。');
      return;
    }
    if (!data.end_date) {
      window.alert('付与終了日を入力してください。');
      return;
    }
    const start_date_moment = moment(data.start_date, 'YYYYMMDD');
    if (!start_date_moment.isValid()) {
      window.alert('付与開始日を正しい形式で入力してください。');
      return;
    }
    const end_date_moment = moment(data.end_date, 'YYYYMMDD');
    if (!end_date_moment.isValid()) {
      window.alert('付与終了日を正しい形式で入力してください。');
      return;
    }
    if (data.get_times === "") {
      data.get_times = 0;
    }

    var params = {...data};
    params.stampcard_id = values.stampcard_id;
    params.point = Number(data.point);
    params.stamp_lat = (data.stamp_lat !== '' && data.stamp_lat !== null) ? Number(data.stamp_lat) : null;
    params.stamp_lon = (data.stamp_lon !== '' && data.stamp_lon !== null) ? Number(data.stamp_lon) : null;
    params.start_date = start_date_moment.toDate();
    params.end_date = end_date_moment.toDate();
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;
    if (data.uploadfiletype === null) params.uploadfile = null;
    if (data.stamp_uuid === null) params.stamp_uuid = uuidv4();
    params.get_times = Number(data.get_times);

    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stamp/master/stamp/upsert/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleStampResetClick(values);
    }
  }

  /* QRダウンロードボタン押下 */
  const handleStampDownloadClick = () => {
    const svg = qrRef.current;
    const svgString = new XMLSerializer().serializeToString(svg);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width + 20; // 余白を追加
      canvas.height = img.height + 20; // 余白を追加
      ctx.fillStyle = 'white'; // 余白の色を指定
      ctx.fillRect(0, 0, canvas.width, canvas.height); // 余白を描画
      ctx.drawImage(img, 10, 10); // QRコードを描画
      // canvas.width = img.width;
      // canvas.height = img.height;
      // ctx.drawImage(img, 0, 0);

      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = stampValues.stamp_id + '_' + stampValues.stamp_name + '.png';
      a.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  // const renderDeleteButton = (data) => {
  //   return (
  //     <button type="button"
  //       disabled={lockData || data.ticket_id === null}
  //       className={`btn btn-primary mx-1 w-50`}
  //       onClick={() => handleDeleteClick(data)}>
  //       削除
  //     </button>
  //   )
  // }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-60`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const renderStampUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleStampUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderStampRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleStampRegistClick(data)}>
        登録
      </button>
    )
  }

  // const renderStampDeleteButton = (data) => {
  //   return (
  //     <button type="button"
  //       disabled={lockData || data.ticket_id === null}
  //       className={`btn btn-primary mx-1 w-50`}
  //       onClick={() => handleStampDeleteClick(data)}>
  //       削除
  //     </button>
  //   )
  // }

  const renderStampResetButton = (data) => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-60`}
        onClick={() => handleStampResetClick(data)}>
        リセット
      </button>
    )
  }

  const handleChange = (e) => {
    const target = e.target;
    var value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }

  const handleStampChange = (e) => {
    const target = e.target;
    var value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setStampValues({ ...stampValues, [name]: value });
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setStampValues({
        ...stampValues,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: stampValues.stamp_image_url
      });
    }
    reader.readAsDataURL(value);
  }

  const handleChangeStampAchievementImage = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setValues({
        ...values,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: values.stamp_achievementimage_url
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setStampValues({ ...stampValues, uploadfile: null, uploadfiletype: null, deletefile: stampValues.stamp_image_url });
  }

  const handleDeleteStampAchievementImage = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.stamp_achievementimage_url });
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>スタンプカードID</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="stampcard_id" defaultValue={values.stampcard_id} placeholder="新規" readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>スタンプカード名</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="stampcard_name" value={values.stampcard_name} onChange={handleChange} placeholder="スタンプカード名を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="point_limit">ポイント上限</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="text" className="form-control" id="point_limit" name="point_limit" value={values.point_limit} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">達成時スタンプ画像</label>
          </div>
          <div className="col-5 align-self-center stamp- bg-stamp p-1">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="stampAchievementImageUrl" id="stampAchievementImageUrl" value="" onChange={handleChangeStampAchievementImage} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <p><object id="uploadfileview" className="w-100" data={values.uploadfile} type={values.uploadfiletype}><img className="w-100" src={values.uploadfile} alt='uploadfile' /></object></p>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteStampAchievementImage} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="start_date">利用開始日</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="start_date" value={values.start_date.replace(/-/g, "")} onChange={handleChange} placeholder="YYYYMMDD" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="end_date">利用終了日</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="end_date" value={values.end_date.replace(/-/g, "")} onChange={handleChange} placeholder="YYYYMMDD" />
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          {/* <div className="col-2 text-center">
            {renderDeleteButton(values)}
          </div> */}
          <div className="col-2 text-center">
            {renderResetButton(values)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  スタンプカードID
                </td>
                <td className="text-center align-middle">
                  スタンプカード名
                </td>
                <td className="text-center align-middle">
                  ポイント上限
                </td>
                <td className="text-center align-middle">
                  達成時スタンプ画像
                </td>
                <td className="text-center align-middle">
                  開始日
                </td>
                <td className="text-center align-middle">
                  終了日
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {stampcardData.map((data, idx) => (
                <tr key={data.stampcard_id}>
                  {/* スタンプカードID */}
                  <td className="text-center align-middle">
                    {data.stampcard_id}
                  </td>
                  {/* スタンプカード名 */}
                  <td className="text-center align-middle">
                    {data.stampcard_name}
                  </td>
                  {/* ポイント上限 */}
                  <td className="text-center align-middle">
                    {data.point_limit}
                  </td>
                  {/* 達成時スタンプ画像 */}
                  <td className="text-center align-middle">
                    {data.stamp_achievementimage_url}
                  </td>
                  {/* 開始日 */}
                  <td className="text-center align-middle">
                    {data.start_date}
                  </td>
                  {/* 終了日 */}
                  <td className="text-center align-middle">
                    {data.end_date}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  const renderStampInput = () => {
    return (
      <div className="mx-1">
        <div className="row">
          <div className="col-8">
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <span>スタンプID</span>
              </div>
              <div className="col-4 text-center align-self-center">
                <input type="text" className="form-control" name="stamp_id" defaultValue={stampValues.stamp_id} placeholder="新規" readOnly />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <span>スタンプ名</span>
              </div>
              <div className="col-8 text-center align-self-center">
                <input type="text" className="form-control" name="stamp_name" value={stampValues.stamp_name} onChange={handleStampChange} placeholder="スタンプ名を入力してください" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <label htmlFor="point">付与ポイント</label>
              </div>
              <div className="col-8 text-left align-self-center">
                <input type="text" className="form-control" id="point" name="point" value={stampValues.point} onChange={handleStampChange} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <label htmlFor="remarks">スタンプ画像</label>
              </div>
              <div className="col-8 align-self-center stamp- bg-stamp p-1">
                {!(stampValues.uploadfile) && <input type="file" className="form-control-file" name="ticketImage" id="ticketImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
                {stampValues.uploadfile && <p><object id="uploadfileview" className="w-100" data={stampValues.uploadfile} type={stampValues.uploadfiletype}><img className="w-100" src={stampValues.uploadfile} alt='uploadfile' /></object></p>}
                {stampValues.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <label htmlFor="start_date">付与開始日</label>
              </div>
              <div className="col-8 text-center align-self-center">
                <input type="text" className="form-control" name="start_date" value={stampValues.start_date.replace(/-/g, "")} onChange={handleStampChange} placeholder="YYYYMMDD" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <label htmlFor="end_date">付与終了日</label>
              </div>
              <div className="col-8 text-center align-self-center">
                <input type="text" className="form-control" name="end_date" value={stampValues.end_date.replace(/-/g, "")} onChange={handleStampChange} placeholder="YYYYMMDD" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <label htmlFor="get_times">獲得可能回数</label>
              </div>
              <div className="col-4 text-left align-self-center">
                <input type="text" className="form-control" id="get_times" name="get_times" value={stampValues.get_times} onChange={handleStampChange} />
              </div>
              <div className="col-4 text-left align-self-center text-nowrap">
                無制限に利用可能とする場合は「0」を入力してください
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <span>スタンプUUID</span>
              </div>
              <div className="col-8 text-center align-self-center">
                <input type="text" className="form-control" name="stamp_uuid" defaultValue={stampValues.stamp_uuid} placeholder="新規" readOnly />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <span>緯度</span>
              </div>
              <div className="col-8 text-center align-self-center">
                <input type="text" className="form-control" name="stamp_lat" value={stampValues.stamp_lat || ''} onChange={handleStampChange} placeholder="緯度(33前後)をXX.XXXXXX形式で入力してください" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 text-center align-self-center">
                <span>経度</span>
              </div>
              <div className="col-8 text-center align-self-center">
                <input type="text" className="form-control" name="stamp_lon" value={stampValues.stamp_lon || ''} onChange={handleStampChange} placeholder="経度(130前後)をXXX.XXXXXX形式で入力してください" />
              </div>
            </div>
            <div className="row mb-2 pb-4">
              <div className="col-3 text-center">
                {renderStampRegistButton(stampValues)}
              </div>
              {/* <div className="col-2 text-center">
            {renderStampDeleteButton(stampValues)}
          </div> */}
              <div className="col-2 text-center">
                {renderStampResetButton(values)}
              </div>
            </div>
          </div>
          <div className='col-4'>
            {(setting && setting['LINE_LIFF_URL'] && stampValues && stampValues.stamp_uuid) &&
              <React.Fragment>
                <div className="row">
                  <div className="col text-center">
                    <QRCode className="qr-img" value={`${setting['LINE_LIFF_URL']}?page=${constClass.STAMPSCAN}&stamp_uuid=${stampValues.stamp_uuid}`} ref={qrRef} />
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <button type="button"
                      className={`btn btn-primary mx-1 w-60`}
                      onClick={() => handleStampDownloadClick()}>
                      ダウンロード
                    </button>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }

  const renderStampList = (stampData) => {
    return (
      stampData &&
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  スタンプID
                </td>
                <td className="text-center align-middle">
                  スタンプ名
                </td>
                <td className="text-center align-middle">
                  スタンプ画像
                </td>
                <td className="text-center align-middle">
                  付与ポイント
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {stampData.map((data, idx) => (
                <tr key={data.stamp_id}>
                  {/* スタンプID */}
                  <td className="text-center align-middle">
                    {data.stamp_id}
                  </td>
                  {/* スタンプ名 */}
                  <td className="text-center align-middle">
                    {data.stamp_name}
                  </td>
                  {/* スタンプ画像 */}
                  <td className="text-center align-middle">
                    {data.stamp_image_url}
                  </td>
                  {/* 付与ポイント */}
                  <td className="text-center align-middle">
                    {data.point}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderStampUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="container">

      {stampcardData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {stampcardData !== null && (renderList())}
      {renderInput()}
      {(stampcardData !== null && values.stampcard_id) && (renderStampList(stampcardData.find(s => s.stampcard_id === values.stampcard_id).m_stamp))}
      {(stampcardData !== null && values.stampcard_id) && (renderStampInput())}
    </div>
  )
}

export default Stampcard;