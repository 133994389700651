import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams, generatePath,  } from 'react-router';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const Shop = (props) => {
  const { siteId } = useParams();
  const [shopNew, setShopNew] = useState({ shop_id: 0, shop_name: "", shop_code: "", start_date: null, end_date: new Date('9999-12-31') });
  const [shopData, setShopData] = useState(null);
  const { addToast } = useToasts();
  const history = useHistory();

  const refreshShop = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const getShop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/${siteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    // 日付型を変換
    const shop = getShop.map(s => ({ ...s, start_date: new Date(s.start_date), end_date: new Date(s.end_date) }));
    shop.sort((a, b) => a.shop_id < b.shop_id ? -1 : 1);
    setShopData(shop);
    setShopNew({ shop_id: "", shop_name: "", shop_code: "", start_date: null, end_date: new Date('9999-12-31') });
  }, [siteId])

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    const target = event ? event.target : null;
    var value = target ? (target.type === "select" ? target.selected : target.value) : event; // valueが直接セットされる場合に対応
    if (index === 'new') {
      const new_data = { ...shopNew, [key]: value };
      setShopNew(new_data)
    } else {
      const _shopData = [...shopData]
      _shopData[index] = { ..._shopData[index], [key]: value };
      setShopData(_shopData)
    }
  }

  //更新ボタン押下時
  const editButtonClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (data.shop_id === "") {
      window.alert('店舗IDを入力してください。');
      return;
    }
    if (data.shop_id && !(String(data.shop_id)).match(/^[0-9]+$/)) {
      window.alert('店舗IDは半角整数で入力してください。');
      return;
    }
    if (!data.shop_name) {
      window.alert('店舗名を入力してください。');
      return;
    }
    if (data.shop_name && data.shop_name.length > 100) {
      window.alert('店舗名は100字以内で入力してください。');
      return;
    }
    if (data.shop_code === "") {
      window.alert('店舗コードを入力してください。');
      return;
    }
    if (data.shop_code && data.shop_code.length > 10) {
      window.alert('店舗コードは10字以内で入力してください。');
      return;
    }
    if (!data.start_date) {
      window.alert('適用開始日を入力してください。');
      return;
    }
    if (!data.end_date) {
      window.alert('適用終了日を入力してください。');
      return;
    }
    if (data.start_date > data.end_date) {
      window.alert('適用開始日と終了日が逆転しています。');
      return;
    }

    // 確認メッセージ
    if (!window.confirm(`店舗マスタ[${data.shop_id}:${data.shop_name}]を${data.site_id ? '更新' : '登録'}します。よろしいですか？`)) {
      return;
    }

    const params = {
      site_id: siteId,
      shop_id: data.shop_id,
      shop_name: data.shop_name,
      shop_code: data.shop_code,
      start_date: data.start_date,
      end_date: data.end_date,
      upd_date: data.upd_date,
    };
    // const params = data;
    let res;
    try {
      if (data.site_id !== undefined) {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/update/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } else {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/insert/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshShop();
      }
    }
  }

  // 登録ボタン
  const registerButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary mx-1`}
        onClick={() => editButtonClick(data)}>
        {data.site_id ? '更新' : '登録'}
      </button>
    )
  }

  // レジボタン
  const cashierButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-link mx-1`}
        onClick={() => history.push(`${generatePath(`${props.match.path}cashier/${data.shop_id}`, { siteId })}`)}>
        レジ登録
      </button>
    )
  }

  useEffect(() => {
    refreshShop();
  }, [refreshShop]);

  return (
    <div className="container">

      {shopData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {shopData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center" width="10%">
                    <span className='needmark'>※</span>
                    店舗ID
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    店舗名
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    店舗コード
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    適用開始日
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    適用終了日
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr>
                  {/* 店舗ID */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"shop_id_new"} name="shop_id" value={shopNew.shop_id || ""} onChange={handleChangeCell('new', 'shop_id')}></input>
                  </td>
                  {/* 店舗名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"shop_name_new"} name="shop_name" value={shopNew.shop_name || ""} onChange={handleChangeCell('new', 'shop_name')}></input>
                  </td>
                  {/* 店舗コード */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"shop_code_new"} name="shop_code" value={shopNew.shop_code || ""} onChange={handleChangeCell('new', 'shop_code')}></input>
                  </td>
                  {/* 適用開始日 */}
                  <td className="text-center align-middle">
                    <DatePicker className="custom-select text-left"
                      locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'start_date_new'} name={'start_date'}
                      popperPlacement='bottom'
                      selected={shopNew.start_date}
                      onChange={handleChangeCell('new', 'start_date')}
                      placeholderText="- 選択 -"
                      isClearable={false} />
                  </td>
                  {/* 適用終了日 */}
                  <td className="text-center align-middle">
                    <DatePicker className="custom-select text-left"
                      locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'end_date_new'} name={'end_date'}
                      popperPlacement='bottom'
                      selected={shopNew.end_date}
                      onChange={handleChangeCell('new', 'end_date')}
                      placeholderText="- 選択 -"
                      isClearable={false} />
                  </td>
                  {/* 編集 */}
                  <td className="text-center align-middle">
                    {registerButton(shopNew)}
                  </td>
                </tr>
                {shopData.map((data, idx) => (
                  <tr key={data.shop_id}>
                    {/* 店舗ID */}
                    <td className="text-center align-middle">
                      {data.shop_id}
                    </td>
                    {/* 店舗名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"shop_name" + idx} name="shop_name" value={data.shop_name || ""} onChange={handleChangeCell(idx, 'shop_name')}></input>
                    </td>
                    {/* 店舗コード */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"shop_code" + idx} name="shop_code" value={data.shop_code || ""} onChange={handleChangeCell(idx, 'shop_code')}></input>
                    </td>
                    {/* 適用開始日 */}
                    <td className="text-center align-middle">
                      <DatePicker className="custom-select text-left"
                        locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'start_date' + idx} name={'start_date'}
                        popperPlacement='bottom'
                        selected={data.start_date}
                        onChange={handleChangeCell(idx, 'start_date')}
                        placeholderText="- 選択 -"
                        isClearable={false} />
                    </td>
                    {/* 適用終了日 */}
                    <td className="text-center align-middle">
                      <DatePicker className="custom-select text-left"
                        locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'end_date' + idx} name={'end_date'}
                        popperPlacement='bottom'
                        selected={data.end_date}
                        onChange={handleChangeCell(idx, 'end_date')}
                        placeholderText="- 選択 -"
                        isClearable={false} />
                    </td>
                    {/* 編集 */}
                    <td className="text-center align-middle table-cell text-nowrap">
                      {registerButton(data)}
                      {cashierButton(data)}
                      {/* {deleteButton(data)} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Shop